import Home from "pages/Home";
import Scouting from "pages/Scouting";
import Production from "pages/Production";
import Work from "pages/Work";
import About from "pages/About";
import Library from "pages/Library";
import Basket from "pages/Basket";
import Contact from "pages/Contact";
import { IPage } from "./interface";

export const googleAnalytics = "UA-160031351-1";

export const PAGES: { [s: string]: IPage } = {
  homeSlash: {
    menu: "Home",
    title: "UK Production Service & Location Company",
    description:
      "Salt Film are a UK based production service & location company. We have an extensive portfolio of locations & over 20 years experience scouting & facilitating location shoots",
    url: "/",
    component: Home,
    menuHide: true,
  },
  home: {
    menu: "Home",
    title: "UK Production Service & Location Company",
    description:
      "Salt Film are a UK based production service & location company. We have an extensive portfolio of locations & over 20 years experience scouting & facilitating location shoots",
    url: "/home",
    component: Home,
    menuHide: true,
  },
  library: {
    menu: "Library",
    title: "UK Location Library",
    description:
      "View our extensive library of locations all across the UK. Whatever project you're working on, we have the perfect location available for you",
    url: "/library",
    component: Library,
    menuHide: false,
  },
  scouting: {
    menu: "Scouting",
    title: "Location Scouting Managers",
    description:
      "Salt Film location scouting services for any project. If you're looking for locations in the UK or beyond, our location scouts leave no stone unturned to find the perfect location",
    url: "/scouting",
    component: Scouting,
    menuHide: false,
  },
  production: {
    menu: "Production",
    title: "UK Production Service Management",
    description:
      "Salt has twenty years of production experience here in the UK. We have managed shoots from feature films and TV commercials to stills shoots",
    url: "/production",
    component: Production,
    menuHide: false,
  },
  work: {
    menu: "Work",
    title: "Production Service Portfolio | Our Work",
    description:
      "View our portfilio of work. Take a look through our past projects where we undertook production service and location scouting",
    url: "/work",
    component: Work,
    menuHide: false,
  },
  about: {
    menu: "About Us",
    title: "About Us",
    description:
      "Learn more about Salt Film, including the services we offer, our experience and backgrounds and why you should work with us on an upcoming project",
    url: "/about",
    component: About,
    menuHide: false,
  },
  contact: {
    menu: "Contact",
    title: "Contact Us",
    description:
      "Contact Salt Film. View all of our contact information to see how you can get in touch with us to discuss your project",
    url: "/contact",
    component: Contact,
    menuHide: false,
  },
  basket: {
    menu: "Basket",
    title: "Your Location Basket",
    description:
      "View your location basket. Your basket allows you to build up your own collection of favourites from our location library",
    url: "/basket",
    component: Basket,
    menuHide: true,
  },
};

type IErrorMessage = {
  title: string;
  description: string;
};
type IErrorMessages = {
  [key: string]: IErrorMessage;
};

export const errorMessages: IErrorMessages = {
  "404": {
    title: "Sorry, we can’t find the page you were looking for",
    description:
      "Please check the URL and try again, or click the link below to return to the homepage",
  },
  internal: {
    title: "Sorry, something has gone wrong",
    description:
      "Salt administrators have been informed of the issue, please try again later",
  },
  image: {
    title: "Image search unavailable",
    description:
      "Unfortunately the image search functionality isn't currently working. Salt administrators have been informed and will rectify as soon as possible",
  },
};

export enum ActionType {
  addToBasket = "addToBasket",
  removeFromBasket = "removeFromBasket",
  clearBasket = "clearBasket",
  addFilterTag = "addFilterTag",
  addFilterTags = "addFilterTags",
  removeFilterTag = "removeFilterTag",
  removeAllFilters = "removeAllFilters",
}

/* Styles */

export const mediaStyle = {
  header: {
    "& .MuiCardHeader-content": {
      flex: "1 1 auto",
      width: "100%",
    },
  },
  card: {
    position: "relative" as "relative",
    "&:hover > $overlay": {
      display: "block",
    },
    "&:hover > $media": {
      transform: "scale(1.2)",
    },
  },
  media: {
    cursor: "pointer",
    height: 0,
    paddingTop: "56%",
    transition: "all .5s",
  },
  parallax: {
    marginTop: "-70px",
    paddingTop: "70px",
  },
  overlay: {
    position: "absolute" as "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    color: "#fff",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "none",
  },
  contentTitle: {
    margin: "8px",
  },
};
