import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "react-fetching-library";
import Interweave from "interweave";
import {
  Grid,
  Typography,
  makeStyles,
  Box,
  Fade,
  IconButton,
  Button,
} from "@material-ui/core";
import {
  InfoOutlined as InfoIcon,
  ArrowForward as ArrowIcon,
} from "@material-ui/icons";

import { fetchTeam } from "resources/api";
import { ITeam } from "resources/interface";

import Template from "structure/Template";
import About from "panels/About";
import InstagramCarousel from "panels/Instagram";

import hero_1 from "assets/scouting_hero/image.jpg";
import hero_2 from "assets/scouting_hero/image@2x.jpg";
import hero_3 from "assets/scouting_hero/image@3x.jpg";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  hero: {
    height: "80vh",
    width: "100vw",
    position: "relative",
    display: "block",
    overflow: "hidden !important",
    zIndex: 0,
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      objectFit: "cover",
    },
  },

  title: {
    position: "absolute",
    top: "40vh",
    left: "30px",
    "& h1": {
      fontSize: "44px",
      letterSpacing: "-0.46px",
      color: palette.common.white,
    },
    [breakpoints.up("md")]: {
      left: "80px",
    },
  },

  services: {
    padding: "60px 30px 30px 30px",
    "& img": {
      width: "calc(100vw - 30px)",
      margin: "100px 0 0 -50px",
    },
    [breakpoints.up("md")]: {
      padding: "80px",
    },
  },

  goal: {
    backgroundColor: palette.primary.main,
    padding: "100px 30px",
    textAlign: "center",
    "& h2": {
      color: palette.common.white,
    },
    "& h3": {
      color: palette.common.white,
      [breakpoints.up("md")]: {
        maxWidth: "650px",
        margin: "0 auto",
      },
    },
    "& p": {
      color: "#99a4a0",
    },
  },

  team: {
    padding: "30px",
    [breakpoints.up("md")]: {
      padding: "80px",
    },
  },

  info: {
    position: "absolute",
    left: "10px",
    bottom: "10px",
    color: palette.common.white,
  },

  description: {
    position: "absolute",
    backgroundColor: palette.common.white,
    opacity: "0.9",
    bottom: "0",
    padding: "15px",
    fontSize: "14px",
    width: "100%",
  },

  button: {
    padding: "20px 30px",
    borderRadius: "100vh",
    marginTop: "64px",
    letterSpacing: "2.4px",
    color: palette.primary.main,
    backgroundColor: palette.common.white,
    "&.reverse": {
      color: palette.common.white,
      backgroundColor: palette.primary.main,
    },
    "& svg": {
      width: "15px",
      marginLeft: "15px",
    },
  },
}));

const Services = () => {
  const classes = useStyles();

  return (
    <Box className={classes.services}>
      <About title="Scouting services" copy="scouting_services" />
    </Box>
  );
};

const Goal = () => {
  const classes = useStyles();

  return (
    <Box className={classes.goal}>
      <About title="Book a location manager" copy="scouting_contact" />
      <Button
        variant="contained"
        component={RouterLink}
        to="/contact"
        className={classes.button}
      >
        Check availability
        <ArrowIcon />
      </Button>
    </Box>
  );
};

const TeamMember = ({
  id,
  full_name,
  job_title,
  member_description,
  member_image,
  isLibrary,
}: ITeam & { isLibrary: boolean }) => {
  const classes = useStyles();
  const [showDesc, setShowDesc] = useState(false);

  return (
    <Grid item xs={12} md={isLibrary ? 4 : 3} key={id}>
      <Box
        style={{ position: "relative" }}
        onMouseEnter={() => setShowDesc(true)}
        onMouseLeave={() => setShowDesc(false)}
      >
        <img src={member_image} alt={full_name} width="100%" />
        {!!member_description && (
          <>
            <Fade in={showDesc} timeout={1000}>
              <Box className={classes.description}>
                <Interweave content={member_description} />
              </Box>
            </Fade>
            {!showDesc && (
              <IconButton
                onClick={() => setShowDesc(true)}
                className={classes.info}
              >
                <InfoIcon />
              </IconButton>
            )}
          </>
        )}
      </Box>
      <Typography variant="h4">{full_name}</Typography>
      <Typography>{job_title}</Typography>
    </Grid>
  );
};

export const Team = ({ isLibrary = false }: { isLibrary: boolean }) => {
  const { payload } = useQuery(fetchTeam);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1">Meet the Team</Typography>
      </Grid>
      {payload &&
        payload
          .filter(({ department }) =>
            isLibrary ? department === "library" : department !== "library"
          )
          .map((member) => (
            <TeamMember key={member.id} {...member} isLibrary={isLibrary} />
          ))}
    </Grid>
  );
};

const Scouting = () => {
  const classes = useStyles();

  return (
    <Template type="home" page="scouting">
      <>
        <div className={classes.hero}>
          <img
            src={hero_1}
            srcSet={`${hero_1} 384w, ${hero_2} 768w, ${hero_3} 1152w`}
            alt="Scouting"
          />
          <Box className={classes.title}>
            <Typography variant="h1">Scouting</Typography>
          </Box>
        </div>
        <Services />
        <Goal />
        <InstagramCarousel limit={9} />
        <Box className={classes.team}>
          <Team />
        </Box>
      </>
    </Template>
  );
};

export default Scouting;
