import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParameterizedQuery } from "react-fetching-library";
import {
  Button,
  makeStyles,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  Chip,
} from "@material-ui/core";
import {
  ArrowForward as ArrowIcon,
  ArrowBack as BackIcon,
} from "@material-ui/icons";
// import { AddShoppingCart as AddAllIcon, AssignmentReturn as BackIcon } from '@material-ui/icons';

import { fetchLocation, fetchLocationImages } from "resources/api";

import Template from "structure/Template";
import Images from "library/Images";
import DownloadImages from "images/Download";
import DropboxImages from "images/Dropbox";
import EmailLink from "library/EmailLink";

function htmlDecode(input: string) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  box: {
    padding: "30px",
    [breakpoints.up("md")]: {
      padding: "40px 80px",
    },
  },

  buttons: {
    display: "flex",
    padding: "25px 0 50px 0",
  },

  button: {
    padding: "20px 30px",
    borderRadius: "100vh",
    marginTop: "64px",
    letterSpacing: "2.4px",
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    "& svg": {
      width: "15px",
      marginLeft: "15px",
    },
  },
}));

const Error = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1">No location found</Typography>
      <Typography>There is no location for that ID</Typography>
      <Button
        variant="contained"
        component={RouterLink}
        to={`/library`}
        className={classes.button}
      >
        Search library
        <ArrowIcon />
      </Button>
    </>
  );
};

type Props = {
  id: string;
};
const Location = ({ id }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const { payload: payloadLocation, query: queryLocation } =
    useParameterizedQuery(fetchLocation);
  const { payload: payloadImages, query: queryImages } =
    useParameterizedQuery(fetchLocationImages);
  //  const { dispatch } = useLocationContext();

  useEffect(() => {
    if (id) {
      localStorage.setItem("location", id);
      queryLocation(id);
      queryImages(id);
    }
  }, [id]);

  if (!payloadLocation || !payloadImages) return null;

  const { fs_area_reference, fs_postcode } = payloadLocation[0] || {};

  /*  const ActionButtons = () => (
    <>
      <Tooltip title="Back to results" arrow className={classes.back}>
        <IconButton aria-label="back to results" component={RouterLink} to="/library">
          <BackIcon className={classes.icon} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Add all location images to basket" arrow>
        <IconButton
          aria-label="add to basket"
          onClick={() =>
            dispatch({
              type: ActionType.addToBasket,
              selectedLocation: Object.assign({}, payloadLocation[0], {
                images: payloadImages,
              }),
            })
          }
        >
          <AddAllIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </>
  ); */

  let link = `location/${id}`;
  if (fs_area_reference)
    link += `/${encodeURIComponent(
      fs_area_reference.trim().replaceAll(" ", "-")
    )}`;

  const area = fs_area_reference ? htmlDecode(fs_area_reference) : "Not found";
  const postcode = (fs_postcode && fs_postcode.split(" ")[0]) || "";

  return (
    <Template
      type="location"
      page={link}
      title={`${area} ${postcode} - Location`}
      description={`${area} ${postcode}`}
    >
      <Box className={classes.box}>
        {!payloadLocation.length ? (
          <Error />
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={desktop ? "row" : "column"}
              justifyContent="space-between"
            >
              <Box>
                <Typography>#{id}</Typography>
                <Typography variant="h1">
                  {area} {postcode}
                </Typography>
                <IconButton
                  aria-label="back to results"
                  component={RouterLink}
                  to="/library"
                  style={{ paddingLeft: 0 }}
                >
                  <Chip
                    color="primary"
                    label="Back"
                    icon={<BackIcon />}
                    deleteIcon={<></>}
                  />
                </IconButton>
              </Box>
              <Box className={classes.buttons}>
                <DownloadImages id={id} />
                <DropboxImages locId={id} images={payloadImages} />
                <EmailLink id={id} />
              </Box>
            </Box>
            <Images location={payloadLocation[0]} images={payloadImages} />
          </>
        )}
      </Box>
    </Template>
  );
};

export default Location;
