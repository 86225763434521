import React, { useState, ChangeEvent, FormEvent } from "react";
import { Redirect } from "react-router-dom";
import {
  IconButton,
  FormControl,
  InputBase,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { ReactComponent as SearchIcon } from "assets/icons/id.svg";

const useStyles = makeStyles(() => ({
  input: {
    height: "54px",
    borderRadius: "100vh",
    backgroundColor: "#e6e9e8",
    paddingTop: "10px",
    "& .MuiInputBase-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
  inputMobile: {
    width: "100%",
    height: "54px",
    paddingTop: "10px",
    "& .MuiInputBase-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
  label: { color: "#000", fontSize: "14px", marginLeft: "10px" },
  labelMobile: { color: "#000", fontSize: "14px", marginLeft: "18px" },
}));

interface IProps {
  style?: "mobile" | "desktop";
}
const IDSearch = ({ style = "desktop" }: IProps) => {
  const classes = useStyles();
  const isMobile = style === "mobile";

  const [userInput, setUserInput] = useState("");
  const [redirect, setRedirect] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUserInput(e.target.value);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userInput.length > 4) setRedirect(true);
  };

  if (redirect) return <Redirect to={`/location/${userInput}`} />;

  if (isMobile)
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <FormControl variant={"standard"} className={classes.inputMobile}>
          <TextField
            fullWidth
            label="Enter Location ID"
            value={userInput}
            onChange={handleChange}
            id="location_id"
          />
          <IconButton
            type="submit"
            aria-label="submit"
            style={{
              width: "53px",
              position: "absolute",
              right: "-12px",
              top: "5px",
            }}
          >
            <SearchIcon />
          </IconButton>
        </FormControl>
      </form>
    );

  return (
    <form onSubmit={handleSubmit} style={{ width: "20%" }}>
      <FormControl variant="outlined" className={classes.input}>
        <InputLabel htmlFor="location_id" className={classes.label}>
          Location ID
        </InputLabel>
        <InputBase
          id="location_id"
          type="number"
          value={userInput}
          onChange={handleChange}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ marginLeft: "40px", marginRight: "-7px" }}
            >
              <IconButton
                type="submit"
                aria-label="submit"
                style={{ width: "100%" }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          style={{ marginLeft: "25px" }}
        />
      </FormControl>
    </form>
  );
};

export default IDSearch;
