import React, { ChangeEvent, FormEvent, forwardRef, Ref, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Box,
  Button,
  FormGroup,
  TextField,
} from '@material-ui/core';
import { ArrowForward as ArrowIcon } from '@material-ui/icons';

import { postEmailTo } from 'resources/api';
import { IEmailTo } from 'resources/interface';

import { ReactComponent as CloseIcon } from 'assets/icons/cross-icon.svg';

const useStyles = makeStyles(({ palette }) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  inputField: {
    width: '70vw',
    '& .MuiOutlinedInput-root': {
      borderRadius: '100vh',
    },
    marginBottom: '24px',
  },

  multiline: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '30px',
    },
  },

  button: {
    width: 'fit-content',
    padding: '20px 30px',
    margin: '24px 0',
    borderRadius: '100vh',
    letterSpacing: '2.4px',
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    '& svg': {
      width: '15px',
      marginLeft: '15px',
    },
  },
}));

interface IProps {
  action(): void;
  id: string;
}
const EmailForm = forwardRef(({ action, id }: IProps, ref: Ref<any>) => {
  const classes = useStyles();
  const { mutate } = useMutation(postEmailTo);

  const [postComplete, setPostComplete] = useState('');
  const [formValues, setFormValues] = useState<IEmailTo>({
    frmval_RecipientEmail: '',
    frmval_RecipientName: '',
    frmval_SubjectLine: `Salt Location: ${id}`,
    frmval_SuggestedUrl: `${process.env.REACT_APP_HOST}/location/${id}`,
    frmval_SenderMessage: "Here is a location from the Salt Location Library that I thought you'd be interested in",
    frmval_SenderName: '',
    captchaResponse: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });

  const handleReCaptcha = (token: string | null) => {
    if (token)
      setFormValues({
        ...formValues,
        captchaResponse: token,
      });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formValues.captchaResponse !== '') {
      try {
        const { error } = await mutate(formValues);
        setPostComplete(error ? 'There is a problem sending your email' : 'Thank you, your email has been sent');
      } catch {
        throw new Error('Could not send email');
      }
    }
  };

  return (
    <Dialog open={true} onClose={action} maxWidth="md" ref={ref}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">Email location link</Typography>
        <IconButton aria-label="close" onClick={action}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!!postComplete.length ? (
          <Box className={classes.inputField}>
            <Typography>{postComplete}</Typography>
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <TextField
                id="frmval_RecipientEmail"
                type="email"
                label="Recipient email"
                required
                onChange={handleChange}
                variant="outlined"
                className={classes.inputField}
              />
              <TextField
                id="frmval_RecipientName"
                label="Recipient name"
                onChange={handleChange}
                variant="outlined"
                className={classes.inputField}
              />
              <TextField
                id="frmval_SubjectLine"
                label="Subject"
                value={formValues.frmval_SubjectLine}
                required
                onChange={handleChange}
                variant="outlined"
                className={classes.inputField}
              />
              <TextField
                id="frmval_SenderMessage"
                label="Body"
                value={formValues.frmval_SenderMessage}
                multiline
                rows={4}
                required
                variant="outlined"
                onChange={handleChange}
                className={`${classes.inputField} ${classes.multiline}`}
              />
              <TextField
                id="frmval_SenderName"
                label="Your name/email"
                onChange={handleChange}
                variant="outlined"
                className={classes.inputField}
              />
              <Box display="flex" alignItems="center" justifyContent="center">
                <ReCAPTCHA sitekey="6LdqgxoUAAAAAIqV872Sj9F6cFxiCB5IEVx1iRmV" onChange={handleReCaptcha} />
              </Box>
              <Button type="submit" variant="contained" className={classes.button}>
                Send
                <ArrowIcon />
              </Button>
            </FormGroup>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default EmailForm;
