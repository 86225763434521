import React from 'react';
import { useQuery } from 'react-fetching-library';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';

import { fetchTeam } from 'resources/api';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  carousel: {
    margin: '100px -30px 35px -95px',
    '& img': {
      margin: '0 30px !important',
    },
    '& li': {
      backgroundColor: `${palette.common.white} !important`,
    },
  },

  galleryOuter: {
    marginTop: '-120px',
    minWidth: '57vw',
    paddingLeft: '5vw',
    '& div:last-child': {
      marginLeft: '4vw',
      '& img': {
        verticalAlign: 'top',
      },
    },
  },

  galleryInner: {
    '& img': {
      width: '16.39vw',
      margin: '0 16px 16px 0',
    },
    /*    '& img:first-child': {
      width: '12.78vw',
    },
    '& img:last-child': {
      width: '12.78vw',
    }, */
  },
}));

export const TeamCarousel = () => {
  const classes = useStyles();
  const { payload } = useQuery(fetchTeam);

  if (!payload) return null;

  return (
    <Box className={classes.carousel}>
      <Carousel showThumbs={false} showIndicators={false} centerMode centerSlidePercentage={65} infiniteLoop>
        {payload.map(({ id, full_name, member_image }) => (
          <img key={id} alt={full_name} src={member_image} />
        ))}
      </Carousel>
    </Box>
  );
};

export const TeamGallery = () => {
  const classes = useStyles();
  const { payload } = useQuery(fetchTeam);

  if (!payload || payload.length < 6) return null;

  return (
    <Box className={classes.galleryOuter}>
      <Box className={classes.galleryInner}>
        <img alt={payload[0].full_name} src={payload[0].member_image} />
        <img alt={payload[1].full_name} src={payload[1].member_image} />
        <img alt={payload[2].full_name} src={payload[2].member_image} />
      </Box>
      <Box className={classes.galleryInner}>
        <img alt={payload[3].full_name} src={payload[3].member_image} />
        <img alt={payload[4].full_name} src={payload[4].member_image} />
        <img alt={payload[5].full_name} src={payload[5].member_image} />
      </Box>
    </Box>
  );
};
