import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useLocationContext } from "resources/hooks";

import Template from "structure/Template";
import LocationsList from "library/List";
import Highlights from "library/Highlights";
import Filters from "library/Filters";
import ChosenTags from "tags/Chosen";
import { Team } from "./Scouting";

import { ReactComponent as FilterIcon } from "assets/icons/filter-icon.svg";
import DesktopFilters from "library/DesktopFilters";

const useStyles = makeStyles(({ breakpoints }) => ({
  box: {
    justifyContent: "space-between",
    boxShadow: "inset 0 -1px 0 0 #e1e1e1, inset 0 1px 0 0 #e1e1e1",
    padding: "15px 30px",
    [breakpoints.up("md")]: {
      padding: "15px 80px",
    },
  },

  filters: {
    lineHeight: "50px",
    "& svg": {
      margin: "-4px 0 0 5px",
      verticalAlign: "middle",
    },
  },
}));

const MobileFilters = () => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <Typography
        onClick={() => setShowFilters(true)}
        className={classes.filters}
      >
        Filters <FilterIcon />
      </Typography>
      <Filters isOpen={showFilters} handleClick={() => setShowFilters(false)} />
    </>
  );
};

const Library = () => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const currLocId = localStorage.getItem("location");
  const scrollRef = useRef<HTMLDivElement>(null);

  const tryScroll = () => {
    if (!!(currLocId && scrollRef && scrollRef.current)) {
      scrollRef.current.scrollIntoView();
      localStorage.removeItem("location");
    } else {
      setTimeout(() => tryScroll(), 1000);
    }
  };

  useEffect(() => {
    tryScroll();
  }, [currLocId, scrollRef]);

  return (
    <Template type="library" page="library">
      <>
        <Box className={classes.box}>
          {desktop ? <DesktopFilters /> : <MobileFilters />}
        </Box>
        <ChosenTags />
        <LocationsList currLocId={currLocId} scrollRef={scrollRef} />
      </>
    </Template>
  );
};

export default Library;
