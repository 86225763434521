import { Component } from 'react';
import ReactGA from 'react-ga';

import { googleAnalytics } from 'resources/constants';
import { debugConsole } from 'resources/utils';

interface IProps {
  location: {
    pathname: string,
    search: string,
  },
}

class Tracking extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    ReactGA.initialize(googleAnalytics, { testMode: process.env.NODE_ENV === 'test'});
  }

  componentDidMount() {
    const { location } = this.props;
    this.logPageChange(location.pathname, location.search);
  }

  componentDidUpdate(prevProps: IProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname || location.search !== prevProps.location.search) {
      this.logPageChange(location.pathname, location.search);
      window.scrollTo(0, 0);
    }
  }

  logPageChange = (pathname: string, search = '') => {
    const reHome = new RegExp('^/$');
    const reBrokerHome = new RegExp('^/[A-Z0-9]{5}$');
    const reBrokerQuote = new RegExp('^/[A-Z0-9]{5}/quote$');
    const reBrokerRetrieve = new RegExp('^/[A-Z0-9]{5}/retrieve-quote/({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}$');

    switch (true) {
      case reHome.test(pathname):
        debugConsole('Track home page', '/home');
        ReactGA.pageview('/home');
        break;
      case reBrokerHome.test(pathname):
        debugConsole('Track broker home page', `${pathname}/home`);
        ReactGA.pageview(`${pathname}/home`);
        break;
      case reBrokerQuote.test(pathname):
        debugConsole('Track quote start', `${pathname}/quote/1-loan`);
        ReactGA.pageview(`${pathname}/quote/1-loan`);
        break;
      case reBrokerRetrieve.test(pathname):
        debugConsole('Track retrieve-quote', pathname);
        ReactGA.pageview(`${pathname}`);
        break;
      default:
        debugConsole('Track other url', `${pathname}${search}`);
        ReactGA.pageview(`${pathname}${search}`);
    }
  }

  render () { return null }
}

export default Tracking;