import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import WhereTags from "tags/Where";
import WhatTags from "tags/What";

import AZSearch from "./AZSearch";
import MapSearch from "./MapSearch";
import ImageSearch from "./ImageSearch";
import IDSearch from "./IDSearch";
import SearchHelp from "./SearchHelp";

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const DesktopFilters = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.box}>
        <WhatTags />
        <WhereTags />
        <ImageSearch />
      </Box>
      <Box className={classes.box} style={{ marginTop: "15px" }}>
        <AZSearch />
        <MapSearch />
        <IDSearch />
        <SearchHelp />
      </Box>
    </>
  );
};

export default DesktopFilters;
