import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParameterizedQuery } from "react-fetching-library";
import { Carousel } from "react-responsive-carousel";
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Visibility as ViewIcon } from "@material-ui/icons";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";

import { fetchLocations } from "resources/api";
import { ITag } from "resources/interface";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  button: {
    width: "100%",
    padding: 0,
    borderRadius: 0,
    "& span": {
      display: "block",
    },
  },
  image: {
    [breakpoints.up("md")]: {
      height: "43.5vw",
    },
    [breakpoints.up("xl")]: {
      height: "42.6vw",
    },
  },
  greenBox: {
    position: "absolute",
    top: 0,
    padding: "15px 20px",
    backgroundColor: palette.primary.main,
    width: "100%",
    opacity: 0.8,
    textAlign: "left",
    color: palette.common.white,
  },
  greenBox2: {
    padding: "15px 20px",
    backgroundColor: palette.primary.main,
    width: "100%",
    textAlign: "left",
    color: palette.common.white,
    "& h4": {
      color: palette.common.white,
    },
    "& h6": {
      color: palette.common.white,
    },
  },
  textBox: {
    position: "absolute",
    bottom: 0,
    left: "10px",
    right: "10px",
    padding: "5px 10px",
    textAlign: "left",
    color: palette.common.white,
    "& h4": {
      color: palette.common.white,
    },
    "& h6": {
      color: palette.common.white,
    },
  },
  icon: {
    margin: "5px 10px 0 0",
    backgroundColor: palette.common.white,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    "& svg": {
      fill: palette.primary.main,
      width: "18px",
      margin: "-2px 0 0 1px",
    },
  },
  icon2: {
    position: "absolute",
    top: "19vw",
    "& svg": {
      width: "3vh",
      height: "3vh",
      zIndex: 2,
    },
  },
}));

const Featured = ({ id, filter_item_title }: ITag) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const {
    loading,
    payload: locations,
    query,
  } = useParameterizedQuery(fetchLocations);

  useEffect(() => {
    query({
      tagIdCSV: id,
      postcodeCSV: "",
      areaCSV: "",
      pageNumber: 1,
      pageSize: 50,
      imageTag: undefined,
    });
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);

  const prev = () => setCurrentSlide(currentSlide - 1);
  const next = () => setCurrentSlide(currentSlide + 1);

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) setCurrentSlide(index);
  };

  if (loading || !locations || !Array.isArray(locations)) return null;

  return (
    <>
      {!desktop && (
        <Box className={classes.greenBox2}>
          <Typography variant="h1">{filter_item_title}</Typography>
        </Box>
      )}
      <Carousel
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        showArrows={false}
        useKeyboardArrows={true}
        selectedItem={currentSlide}
        autoPlay
        infiniteLoop
        interval={5000}
        onChange={updateCurrentSlide}
      >
        {locations.map(
          ({
            loc_image_file1,
            loc_image_ps_id1,
            id,
            fs_area_reference,
            fs_postcode,
            description,
          }) => {
            let link = `location/${id}`;
            if (fs_area_reference)
              link += `/${encodeURIComponent(
                fs_area_reference.trim().replaceAll(" ", "-")
              )}`;

            return (
              <IconButton
                aria-label="view entire location"
                component={RouterLink}
                to={link}
                className={classes.button}
                key={id}
              >
                {desktop && (
                  <Box className={classes.greenBox}>
                    <Typography variant="h1">{filter_item_title}s</Typography>
                  </Box>
                )}
                <img
                  key={loc_image_ps_id1}
                  alt={loc_image_ps_id1}
                  src={`${process.env.REACT_APP_HOST}${loc_image_file1}`}
                  className={classes.image}
                />
                {desktop && (
                  <Box
                    className={classes.greenBox}
                    style={{ bottom: 0, top: "auto", height: "160px" }}
                  />
                )}
                <Box className={desktop ? classes.textBox : classes.greenBox2}>
                  <Typography variant="h4" style={{ fontSize: "12px" }}>
                    #{id}
                  </Typography>
                  <Typography variant="subtitle2">
                    {fs_area_reference},{" "}
                    {fs_postcode && fs_postcode.split(" ")[0]}
                  </Typography>
                  <Box
                    style={{
                      height: "50px",
                      overflow: "hidden",
                      margin: "5px 0 10px 0",
                    }}
                  >
                    <Typography variant="h4" style={{ fontSize: "15px" }}>
                      {description}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Box className={classes.icon}>
                      <ViewIcon />
                    </Box>
                    <Typography variant="subtitle2">
                      View entire location
                    </Typography>
                  </Box>
                </Box>
              </IconButton>
            );

            // return (
            //   <Box style={{ textAlign: "left" }} key={id}>
            //     <IconButton
            //       aria-label="view entire location"
            //       component={RouterLink}
            //       to={link}
            //       style={{ width: "100%", padding: 0 }}
            //     >
            //       <img
            //         key={loc_image_ps_id1}
            //         alt={loc_image_ps_id1}
            //         src={`${process.env.REACT_APP_HOST}${loc_image_file1}`}
            //         // style={{ height: "50vh", width: "auto" }}
            //       />
            //     </IconButton>
            //       {/*<IconButton
            //         aria-label="add entire location to basket"
            //         onClick={() => setAddLocation(true)}
            //         className={classes.button}
            //       >
            //         <AddIcon className={classes.icon2} />
            //         <Typography color="primary">
            //           Add entire location to basket
            //         </Typography>
            // </IconButton>*/}
            //     </Box>
            //   </Box>
            // );
          }
        )}
      </Carousel>
      {desktop && (
        <>
          <IconButton
            edge="end"
            className={classes.icon2}
            onClick={prev}
            style={{ left: "20px" }}
          >
            <ArrowLeft />
          </IconButton>
          <IconButton
            edge="end"
            className={classes.icon2}
            onClick={next}
            style={{ right: "50px" }}
          >
            <ArrowRight />
          </IconButton>
        </>
      )}
    </>
  );
};

export default Featured;
