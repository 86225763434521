import React, { ReactNode } from "react";
import Interweave from "interweave";
import { Box, makeStyles, Typography } from "@material-ui/core";

import { useContentContext } from "resources/hooks";
import { getCopy } from "resources/utils";

const useStyles = makeStyles(({ breakpoints }) => ({
  box: {
    [breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  box2: {
    [breakpoints.up("md")]: {
      width: "51vw",
      marginRight: "7vw",
      "& h3": {
        fontSize: "28px",
      },
    },
  },
  imageH3: {
    [breakpoints.up("md")]: {
      width: "51vw",
    },
  },

  content: {
    [breakpoints.up("md")]: {
      display: "flex",
      alignItems: "start",
    },
  },
}));

interface IProps {
  title?: string;
  copy: string;
  button?: ReactNode;
  image?: ReactNode;
  spread?: boolean;
}
const About = ({ title, copy, button, image, spread = false }: IProps) => {
  const classes = useStyles();
  const { contentContext } = useContentContext();

  return (
    <Box className={spread ? classes.box : ""} flex={1}>
      {title && <Typography variant="h2">{title}</Typography>}
      <Box className={spread ? classes.box2 : ""}>
        {image ? (
          <>
            <Box className={classes.content}>
              <Box>
                <Typography variant="h3">
                  <Interweave content={getCopy(contentContext, `${copy}_1`)} />
                </Typography>
                {image}
                <Typography style={{ marginTop: "30px" }}>
                  <Interweave content={getCopy(contentContext, `${copy}_2`)} />
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <Box>
            <Typography variant="h3">
              <Interweave content={getCopy(contentContext, `${copy}_1`)} />
            </Typography>
            <Typography>
              <Interweave content={getCopy(contentContext, `${copy}_2`)} />
            </Typography>
          </Box>
        )}
        {button}
      </Box>
    </Box>
  );
};

export default About;
