import React, { FunctionComponent } from 'react';
import axios from 'axios';
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core';

import { useLocationContext } from 'resources/hooks';
import { ILocationImage } from 'resources/interface';

import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';

const useStyles = makeStyles(({ palette }) => ({
  box: {
    position: 'relative',
    width: '60px',
    textAlign: 'center',
  },

  icon: {
    color: palette.common.white,
    marginLeft: '-10px',
    '&::before': {
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      paddingBottom: '100%',
      backgroundColor: palette.primary.main,
      content: '""',
      position: 'absolute',
    },
    '& svg': {
      fill: palette.common.white,
      width: '25px',
      height: '25px',
      zIndex: 2,
    },
  },

  text: {
    fontSize: '9px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '0.9px',
  },

  button: {
    padding: '15px 30px',
    borderRadius: '100vh',
    marginTop: '64px',
    maxHeight: '50px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    '& svg': {
      width: '20px',
      marginLeft: '15px',
    },
  },
}));

interface IProps {
  id?: string;
  images?: ILocationImage[];
}
const DownloadImages: FunctionComponent<IProps> = ({ id, images }) => {
  const classes = useStyles();
  const { locationContext } = useLocationContext();

  const handleDownload = async () => {
    const values =
      locationContext.basketList.map(({ id, images }) => ({
        galleryId: id,
        imageIds: images?.map(({ ext_itemps_id }) => ext_itemps_id) || [],
      })) || [];
    try {
      axios({
        url: `${process.env.REACT_APP_HOST}/MzCms_Overrides/TrackedDownload.ashx`,
        method: 'POST',
        responseType: 'blob',
        data: {
          action: 'gallery-images',
          payload: values,
        },
      }).then((response) => {
        const fileName = response.headers['content-disposition'].match(/filename="(.*)"/) || ['', 'SaltLocations.zip'];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName[1]);
        document.body.appendChild(link);
        link.click();
      });
    } catch {
      throw new Error('Could not download basket');
    }
  };

  if (id && images) {
    const imagesCSV = images.reduce((csv, image) => `${csv}${!csv.length ? '' : ','}${image.ext_itemps_id}`, '');
    const link = `${process.env.REACT_APP_HOST}/MzCms_Overrides/TrackedDownload.ashx?cr_i=${imagesCSV}&size=main`;
    return (
      <Box className={classes.box}>
        <IconButton edge="end" className={classes.icon} href={link}>
          <DownloadIcon />
        </IconButton>
        <Typography className={classes.text}>Download</Typography>
      </Box>
    );
  }

  if (id) {
    const link = `${process.env.REACT_APP_HOST}/MzCms_Overrides/TrackedDownload.ashx?cr_i=${id}&size=main`;
    return (
      <Box className={classes.box}>
        <IconButton edge="end" className={classes.icon} href={link}>
          <DownloadIcon />
        </IconButton>
        <Typography className={classes.text}>Download</Typography>
      </Box>
    );
  }

  return (
    <Button variant="contained" className={classes.button} onClick={handleDownload}>
      Download
      <DownloadIcon />
    </Button>
  );
};

export default DownloadImages;
