import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import { Link, LinkProps } from "react-router-dom";

import { PAGES } from "resources/constants";

import AppBar from "./AppBar";

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  drawer: {
    [breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },

  drawerPaper: {
    width: "100%",
    backgroundColor: palette.primary.main,
    color: palette.common.white,
  },

  list: {
    marginTop: "160px",
    padding: "30px",
    "& a:last-of-type": {
      marginTop: "80px",
    },
  },

  footer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    padding: "30px",
    borderTop: `1px solid ${palette.secondary.light}`,
    "& a": {
      color: palette.common.white,
      textDecoration: "underline",
    },
  },
}));

interface IProps {
  isOpen: boolean;
  handleClick: () => void;
}

const Menu = ({ isOpen, handleClick }: IProps) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={handleClick}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <AppBar handleClick={handleClick} useClose pageType="home" />
      <List className={classes.list}>
        {Object.values(PAGES)
          .filter(({ menuHide }) => !menuHide)
          .map(({ url, menu }) => (
            <ListItem
              button
              key={url}
              component={forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
                (props, ref) => (
                  <Link to={url} {...props} ref={ref as any} />
                )
              )}
            >
              <ListItemText
                primary={menu}
                primaryTypographyProps={{ variant: "subtitle1" }}
              />
            </ListItem>
          ))}
      </List>
      <Box className={classes.footer}>
        <Box>
          <a href="mailto: info@saltfilm.com">info@saltfilm.com</a>
        </Box>
        <Box>
          Location managers:{" "}
          <a href="tel: +442076377885">+44 (0) 207 637 7885</a>
        </Box>
        <Box>
          Location library:{" "}
          <a href="tel: +442074399344">+44 (0) 207 439 9344</a>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Menu;
