import React, { useState } from "react";
import { useQuery } from "react-fetching-library";
import { FormControl, makeStyles } from "@material-ui/core";

import { fetchWhatTags } from "resources/api";
import { useLocationContext } from "resources/hooks";
import { ActionType } from "resources/constants";
import { ITag } from "resources/interface";

import MultiChipSelect from "structure/MultiChipSelect";
//import TagsAZ from './AZ';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  input: {
    zIndex: 5,
    width: "60%",
    height: "54px",
    borderRadius: "100vh",
    backgroundColor: "#e6e9e8",
    padding: "12px 0 12px 20px",
    [breakpoints.up("md")]: {
      paddingTop: 0,
      "& input": {
        marginTop: "-5px",
      },
      "& label": {
        marginTop: "-5px",
        color: "#000",
        fontSize: "14px",
      },
    },
  },
  input2: {
    width: '100%'
  },

  buttons: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "block",
    },
  },
}));

type Props = {
  handleClick?: () => void;
};
const WhatTags = ({ handleClick }: Props) => {
  const classes = useStyles();
  const { locationContext, dispatch } = useLocationContext();

  const { tagList } = locationContext;
  const { payload: payloadTags } = useQuery(fetchWhatTags);
  //  const [showTags, setShowTags] = useState(false);
  const [userInput, setUserInput] = useState("");

  /*  const hideTags = useCallback(() => {
    setShowTags(false);
  }, []); */

  const handleChange = (selectedItem: ITag) => {
    setUserInput("");
    if (tagList.includes(selectedItem)) {
      removeSelectedItem(selectedItem);
    } else {
      addSelectedItem(selectedItem);
    }
    handleClick && handleClick();
  };

  const addSelectedItem = (tag: ITag) => {
    dispatch({
      type: ActionType.addFilterTag,
      selectedTag: tag,
    });
  };

  const removeSelectedItem = (tag: ITag) => {
    dispatch({
      type: ActionType.removeFilterTag,
      selectedTag: tag,
    });
  };

  const handleChangeInput = (inputVal: string) => {
    if (inputVal && ![",", "[", "]"].some((char) => inputVal.includes(char))) {
      setUserInput(inputVal);
    } else setUserInput("");

    //    const t = inputVal.split(",");
    //    if (JSON.stringify(t) !== JSON.stringify(tagList)) {
    //      setUserInput(inputVal);
    //    }
  };

  if (!payloadTags) return null;

  return (
    <>
      <FormControl
        variant={handleClick ? "standard" : "outlined"}
        className={handleClick ? classes.input2 : classes.input}
      >
        <MultiChipSelect
          onInputValueChange={handleChangeInput}
          inputValue={userInput}
          inputLabel={handleClick ? "Enter a feature" : "Type in a keyword ..."}
          inputStyle={handleClick ? {} : { disableUnderline: true }}
          availableItems={payloadTags}
          show={payloadTags.length}
          selectedItem={tagList}
          onChange={handleChange}
          onRemoveItem={removeSelectedItem}
          itemToString={(item: ITag) => item.filter_item_title}
        />
      </FormControl>
      {/*<Box className={classes.buttons}>
        <Button variant="contained" color="primary" style={{ marginTop: '8px' }} onClick={() => setShowTags(true)}>
          All features
        </Button>
  </Box>
      {!!showTags && <TagsAZ tags={payloadTags} action={hideTags} />}*/}
    </>
  );
};

export default WhatTags;
