import React, { forwardRef, Ref, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, makeStyles, Dialog, DialogTitle, IconButton, DialogContent, Button } from '@material-ui/core';

import { ITag } from 'resources/interface';
import { useLocationContext } from 'resources/hooks';
import { ActionType } from 'resources/constants';

import { ReactComponent as UploadIcon } from 'assets/icons/attach-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-icon.svg';

const useStyles = makeStyles(({ palette }) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  drop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '45vw',
    padding: '48px',
    marginBottom: '24px',
    borderRadius: '4px',
    border: 'solid 1px #99a4a0',
    backgroundColor: 'rgba(153, 164, 160, 0.14)',
  },

  input: {
    display: 'none',
  },

  button: {
    width: 'fit-content',
    padding: '15px 30px',
    marginTop: '30px',
    borderRadius: '100vh',
    letterSpacing: '2.4px',
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    '& svg': {
      width: '15px',
      marginLeft: '15px',
    },
  },
}));

interface IProps {
  action(): void;
}
const Image = forwardRef(({ action }: IProps, ref: Ref<any>) => {
  const classes = useStyles();
  const { dispatch } = useLocationContext();

  const onDrop = useCallback((acceptedFiles) => {
    chooseFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, maxSize: 10485760 });

  const chooseFile = (files: FileList | null) => {
    if (files && files.length && files[0].size <= 10485760) {
      const tag: ITag = {
        id: '%image',
        filter_item_title: files[0].name,
        data: files[0],
      };

      dispatch({
        type: ActionType.addFilterTag,
        selectedTag: tag,
      });
      action();
    }
  };

  return (
    <Dialog open={true} onClose={action} maxWidth="md" ref={ref}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">Image search</Typography>
        <IconButton aria-label="close" onClick={action}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.drop} {...getRootProps()}>
          {isDragActive && <input accept="image/*" {...getInputProps()} />}
          <Typography variant="h3" align="center">
            {isDragActive ? 'Drop your image here' : 'Drag an image here or upload a file.'}
          </Typography>
          <Typography align="center">Image files should be JPEG or PNG and no larger than 10MB.</Typography>
          {!isDragActive && (
            <>
              <input
                accept="image/*"
                className={classes.input}
                id="icon-button-file"
                type="file"
                onChange={(e) => chooseFile(e.target.files)}
              />
              <label htmlFor="icon-button-file">
                <Button component="span" variant="contained" className={classes.button}>
                  Upload
                  <UploadIcon />
                </Button>
              </label>
            </>
          )}
        </Box>
        <Box style={{ width: '45vw' }}>
          <Typography>
            <b>What is Image Search?</b>
          </Typography>
          <Typography>
            Use any image to search similar features or locations within our library. Simply upload and refine your
            search.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default Image;
