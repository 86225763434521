import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParameterizedQuery } from "react-fetching-library";
import { Box, Button, makeStyles, Typography, Chip } from "@material-ui/core";
import {
  ArrowForward as ArrowIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";

import { ActionType } from "resources/constants";
import { useLocationContext } from "resources/hooks";
import { ILocation, ILocationImage } from "resources/interface";
import { fetchLocationImages } from "resources/api";

import Template from "structure/Template";
import Download from "images/Download";
import LocationRow from "library/Row";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  header: {
    padding: "30px",
    borderBottom: `1px solid ${palette.secondary.light}`,
    "& a": {
      marginTop: "32px",
    },
    "& button": {
      marginTop: "32px",
    },
    [breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      padding: "30px 80px",
      "& a": {
        marginTop: 0,
        marginLeft: "16px",
      },
      "& button": {
        marginTop: 0,
        marginLeft: "16px",
      },
    },
  },

  title: {
    textAlign: "right",
    [breakpoints.up("md")]: {
      textAlign: "left",
    },
  },

  card: {
    borderBottom: `2px solid ${palette.secondary.light}`,
    paddingBottom: "30px",
    marginBottom: "30px",
    alignItems: "start",
    "& img": {
      marginRight: "40px",
    },
    [breakpoints.up("md")]: {
      display: "flex",
      padding: "0 50px 30px 50px",
    },
  },

  button: {
    padding: "20px 30px",
    borderRadius: "100vh",
    marginTop: "64px",
    letterSpacing: "2.4px",
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    "& svg": {
      width: "15px",
      marginLeft: "15px",
    },
  },

  buttonWrapper: {
    position: "relative",
    width: "60px",
    textAlign: "center",
  },

  icon: {
    color: palette.common.white,
    marginLeft: "-10px",
    "&::before": {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      paddingBottom: "100%",
      backgroundColor: palette.primary.main,
      content: '""',
      position: "absolute",
    },
    "& svg": {
      fill: palette.common.white,
      width: "25px",
      height: "25px",
      zIndex: 2,
    },
  },

  text: {
    fontSize: "9px",
    textTransform: "uppercase",
    fontWeight: "bold",
    letterSpacing: "0.9px",
  },
}));

const Header = ({ hasItems }: { hasItems: boolean }) => {
  const classes = useStyles();
  const { dispatch } = useLocationContext();

  const removeAll = () => {
    dispatch({
      type: ActionType.clearBasket,
    });
  };

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.title}>
          <Typography
            variant="h1"
            align="center"
            style={hasItems ? {} : { width: "100%" }}
          >
            Your basket{!hasItems && " is empty"}
          </Typography>
          {hasItems && (
            <Chip
              color="primary"
              label="Clear all"
              deleteIcon={<CancelIcon />}
              onDelete={removeAll}
              onClick={removeAll}
            />
          )}
        </Box>
        {hasItems && (
          <Box display="flex" justifyContent="space-between">
            <Download />
            {/*<Dropbox big />*/}
            {/*<PDFImages />*/}
          </Box>
        )}
      </Box>
      {!hasItems && (
        <Box textAlign="center" marginBottom="60px">
          <Button
            variant="contained"
            component={RouterLink}
            to={`/library`}
            className={classes.button}
          >
            Search library
            <ArrowIcon />
          </Button>
        </Box>
      )}
    </>
  );
};

const BasketLocation = ({ location }: { location: ILocation }) => {
  const { payload, query } = useParameterizedQuery(fetchLocationImages);

  useEffect(() => {
    query(location.id);
  }, [location.id]);

  if (!payload || !payload.length) return null;

  if (payload.length === location.images?.length) {
    // whole location added
    const {
      loc_image_ps_id1,
      loc_image_file1,
      loc_image_ps_id2,
      loc_image_file2,
      loc_image_ps_id3,
      loc_image_file3,
      images,
    } = location;
    const imageArr: Array<ILocationImage> = [];
    if (loc_image_ps_id1 && loc_image_file1)
      imageArr.push({
        ext_itemps_id: loc_image_ps_id1,
        ext_itemps_product_set_image: `${process.env.REACT_APP_HOST}${loc_image_file1}`,
      });
    if (loc_image_ps_id2 && loc_image_file2)
      imageArr.push({
        ext_itemps_id: loc_image_ps_id2,
        ext_itemps_product_set_image: `${process.env.REACT_APP_HOST}${loc_image_file2}`,
      });
    if (loc_image_ps_id3 && loc_image_file3)
      imageArr.push({
        ext_itemps_id: loc_image_ps_id3,
        ext_itemps_product_set_image: `${process.env.REACT_APP_HOST}${loc_image_file3}`,
      });

    const newLoc = {
      ...location,
      images: !imageArr.length ? images?.slice(0, 3) : imageArr,
    };

    return (
      <LocationRow location={newLoc} key={location.id} count={images?.length} />
    );
  }

  return (
    <LocationRow
      location={location}
      key={location.id}
      count={location.images?.length}
    />
  );
};

const Basket = () => {
  const { locationContext } = useLocationContext();
  const { basketList } = locationContext || {};

  return (
    <Template type="basket" page="basket">
      <>
        <Header hasItems={!!basketList.length} />
        <Box>
          {basketList.map((location) => (
            <BasketLocation location={location} />
          ))}
        </Box>
      </>
    </Template>
  );
};

export default Basket;
