import { IContent } from './interface';

export const debugConsole = (label: string, object: object | string) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`DEBUG: ${label}: ${JSON.stringify(object)}`);
  }
};

/*
export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find(key => object[key] === value);
}
*/

export const getCopy = (array: Array<IContent> | undefined, name: string) => {
  if (array) {
    const copy = array.find((obj) => obj.block_name === name);

    if (copy) {
      if (copy.block_content.includes('%%PHONE%%')) {
        const phone = array.find((obj) => obj.block_name === 'salt_phone');
        if (phone) return copy.block_content.replace('%%PHONE%%', '');
      }
      return copy.block_content;
    }
  }
  return '';
};
