import React, { useState } from "react";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";

import { ReactComponent as ImageIcon } from "assets/icons/camera.svg";

import Image from "tags/Image";

const useStyles = makeStyles(({ palette }) => ({
  button: {
    width: "15%",
    minWidth: "180px",
    height: "54px",
    border: "none",
    borderRadius: "100vh",
    backgroundColor: "#99A4A0",
    outline: "none",
    "& svg": {
      verticalAlign: "middle",
      margin: "-5px 10px 0 10px",
      width: "25px",
      height: "25px",
    },
    "&:hover": {
      backgroundColor: "#193027",
    },
  },

  label: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontSize: "14px",
    marginLeft: "15px",
  },

  help: {
    border: "none",
    outline: "none",
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    marginTop: "15px",
    marginRight: "10px",
    backgroundColor: palette.secondary.light,
  },
}));

const ImageSearch = () => {
  const classes = useStyles();

  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <IconButton className={classes.button} onClick={() => setShowPopup(true)}>
        <Typography className={classes.label}>AI Image Search</Typography>
        <ImageIcon />
      </IconButton>
      {/*<Tooltip
          title={
            <>
              <Typography>
                <b>What is Image Search?</b>
              </Typography>
              <Typography>
                Use any image to search similar features or locations within our library. Simply upload and refine your
                search.
              </Typography>
            </>
          }
          arrow
        >
          <IconButton className={classes.help}>
            <Typography style={{ lineHeight: 0 }}>?</Typography>
          </IconButton>
        </Tooltip>*/}
      {showPopup && <Image action={() => setShowPopup(false)} />}
    </>
  );
};

export default ImageSearch;
