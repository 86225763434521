import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import Template from "structure/Template";
import About from "panels/About";
import Logos from "panels/Logos";

import hero_1 from "assets/about_hero/image.jpg";
import hero_2 from "assets/about_hero/image@2x.jpg";
import hero_3 from "assets/about_hero/image@3x.jpg";
import about_1 from "assets/about_image/image.jpg";
import about_2 from "assets/about_image/image@2x.jpg";
import about_3 from "assets/about_image/image@3x.jpg";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  hero: {
    height: "80vh",
    width: "100vw",
    position: "relative",
    display: "block",
    overflow: "hidden !important",
    zIndex: 0,
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      objectFit: "cover",
    },
  },
  title: {
    position: "absolute",
    top: "40vh",
    left: "30px",
    "& h1": {
      fontSize: "44px",
      letterSpacing: "-0.46px",
      color: palette.common.white,
    },
    [breakpoints.up("md")]: {
      left: "80px",
    },
  },

  about: {
    backgroundColor: palette.secondary.light,
    padding: "60px 30px 30px 30px",
    "& img": {
      marginTop: "30px",
    },
    [breakpoints.up("md")]: {
      display: "flex",
      padding: "80px",
      "& img": {
        width: "40%",
        height: "100%",
        margin: "70px 0 70px 50px",
      },
    },
  },

  placeholder: {
    backgroundColor: "#99a4a0",
    width: "100%",
    height: "540px",
    marginTop: "80px",
    opacity: "0.2",
  },

  clients: {
    backgroundColor: palette.primary.main,
    padding: "100px 50px",
    "& h2": {
      color: palette.common.white,
    },
    [breakpoints.up("md")]: {
      padding: "100px 80px",
    },
  },
}));

const AboutSalt = () => {
  const classes = useStyles();

  return (
    <Box className={classes.about}>
      <About title="About Salt" copy="about_salt" />
      <img
        src={about_1}
        srcSet={`${about_1} 384w, ${about_2} 768w, ${about_3} 1152w`}
        alt="About Salt"
      />
    </Box>
  );
};

const Clients = () => {
  const classes = useStyles();

  return (
    <Box className={classes.clients}>
      <Typography variant="h2">Selected Clients</Typography>
      <Logos />
    </Box>
  );
};

const AboutUs = () => {
  const classes = useStyles();

  return (
    <Template type="home" page="about">
      <>
        <div className={classes.hero}>
          <img
            src={hero_1}
            srcSet={`${hero_1} 384w, ${hero_2} 768w, ${hero_3} 1152w`}
            alt="About us"
          />
          <Box className={classes.title}>
            <Typography variant="h1">About Us</Typography>
          </Box>
        </div>
        <AboutSalt />
        <Clients />
      </>
    </Template>
  );
};

export default AboutUs;
