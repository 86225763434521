import React, { useState } from "react";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import {
  ArrowBack as PrevIcon,
  ArrowForward as NextIcon,
} from "@material-ui/icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { ILocationImage } from "resources/interface";

const useStyles = makeStyles(({ palette }) => ({
  image: {
    height: "80vh",
    width: "auto !important",
  },

  icon: {
    position: "absolute",
    top: "47%",
    "&::before": {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      paddingBottom: "120%",
      backgroundColor: palette.common.white,
      content: '""',
      position: "absolute",
    },
    "& svg": {
      fill: palette.primary.main,
      width: "15px",
      height: "15px",
      zIndex: 2,
    },
  },
}));

interface IProps {
  images?: Array<ILocationImage>;
  //  videos?: Array<IWork>;
  init: number;
}
const CarouselImages = ({ images, init }: IProps) => {
  const classes = useStyles();
  const [currentSlide, setCurrentSlide] = useState(init);

  const prev = () => setCurrentSlide(currentSlide - 1);
  const next = () => setCurrentSlide(currentSlide + 1);

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) setCurrentSlide(index);
  };

  return (
    <>
      <Box style={{ position: "relative", overflow: "hidden" }}>
        {images && (
          <Carousel
            selectedItem={currentSlide}
            showThumbs={false}
            showIndicators={false}
            showArrows={false}
            useKeyboardArrows={true}
            onChange={updateCurrentSlide}
          >
            {images.map(({ ext_itemps_id, ext_itemps_product_set_image }) => (
              <img
                key={ext_itemps_id}
                alt={ext_itemps_id}
                src={ext_itemps_product_set_image}
                className={classes.image}
              />
            ))}
          </Carousel>
        )}

        {/*videos && (
        <Carousel
          selectedItem={init}
          showThumbs={false}
          showIndicators={false}
          useKeyboardArrows={true}
          stopOnHover={true}
        >
          {videos.map(({ id, video_url, client_name }) => (
            <iframe
              key={id}
              title={client_name}
              src={`https://player.vimeo.com/video/${video_url}`}
              width="100%"
              height="100%"
              frameBorder="0"
            />
          ))}
        </Carousel>
          )*/}
      </Box>
      <IconButton
        edge="end"
        className={classes.icon}
        onClick={prev}
        style={{ left: "5px" }}
      >
        <PrevIcon />
      </IconButton>
      <IconButton
        edge="end"
        className={classes.icon}
        onClick={next}
        style={{ right: "20px" }}
      >
        <NextIcon />
      </IconButton>
    </>
  );
};

export default CarouselImages;
