import { useState, useEffect, useContext } from "react";

import { mockFetch } from './mock';
import { debugConsole } from "./utils";
import { LocationContext, ContentContext } from "structure/Context";

export const useContentContext = () => useContext(ContentContext);
export const useLocationContext = () => useContext(LocationContext);

let cachedScripts: Array<string> = [];

type TUseScript = (props: {src: string, dataAppKey?: string}) => Array<any>;
export const useScript: TUseScript = ({ src, dataAppKey }) => {
  const [state, setState] = useState({
    loaded: false,
    error: false
  });

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false
      });
    } else {
      cachedScripts.push(src);

      let script = document.createElement('script');
      script.src = src;
      if (dataAppKey) {
        script.setAttribute('data-app-key', dataAppKey);
        script.setAttribute('id', 'dropboxjs');
      }
      script.async = true;

      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false
        });
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src] );

  return [state.loaded, state.error];
}

/*type Hook = (url: string, options?: any) => IFetchState

export const useFetch: Hook = (url, options = {}) => {
  const [state, setState] = useState<IFetchState>({
    loading: true,
    error: false,
    data: [],
  });
  const fullURL = `http://salt.b9.modezero.net/salt/api/${url}.html`;

  useEffect(() => {
    const fetchData = async () => {
      if (process.env.NODE_ENV !== 'production') {
        const json = await mockFetch(url);
        setState({ loading: false, error: false, data: json });
      } else {
        try {
          const response = await fetch(fullURL);
          
          if (response.status === 401) {
            setState({ loading: false, error: true, data: [] });
          } else if (response.status === 400) {
            const json = await response.json();
//            setState({ loading: false, error: json, data: [] });
            setState(() => { throw new Error(json.title) })
          } else if (response.status === 500) {
            setState(() => { throw new Error(`Server responded with 500: ${fullURL}`) })
          } else if (response.status === 502 || response.status === 503) {
            setState(() => { throw new Error('Service Unavailable') })
          } else if (response.status !== 200) {
            const json = await response.json();
            setState(() => { throw new Error(json.title) })
//            setState({ loading: false, error: json.title, data: [] });
          } else {
            const json = await response.json();
            setState({ loading: false, error: false, data: json });
          }
        } catch (err) {
          setState(() => { throw new Error(err) })
        }
      }
    }
    
    fetchData();
  }, []);

  return state;
}
*/
/*


export const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler(evt) {
    if (evt.key === targetKey) {
      if (evt.target.tagName === 'DIV') evt.preventDefault();
      setKeyPressed(true);
    }
  }
  function upHandler(evt) {
    if (evt.key === targetKey) {
      if (evt.target.tagName === 'DIV') evt.preventDefault();
      setKeyPressed(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
}

export const useOutline = () => {
  const [outline, setOutline] = useState(false);

  const handleKeydown = (e) => {
    if (e.keyCode === 9) setOutline(true); // tab event
  }

  const handleClick = () => setOutline(false);
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('click', handleClick);
    }
  });

  return outline;
};
*/