import React from "react";
import { useQuery } from "react-fetching-library";
import { Grid, Typography } from "@material-ui/core";

import { fetchHighlightTags } from "resources/api";

import Featured from "./Featured";
import New from "./New";
import Categories from "./Category";

const Highlights = () => {
  const { payload: highlightTags } = useQuery(fetchHighlightTags);

  if (!highlightTags || !highlightTags.length) return null;

  const featuredTag = highlightTags.find(({ filter_item_title }) =>
    filter_item_title.startsWith("Featured")
  );
  const newTag = highlightTags.find(({ filter_item_title }) =>
    filter_item_title.startsWith("New")
  );
  const favouritesTag = highlightTags.find(({ filter_item_title }) =>
    filter_item_title.startsWith("Favourite")
  );
  const exclusivesTag = highlightTags.find(({ filter_item_title }) =>
    filter_item_title.startsWith("Exclusive")
  );

  if (!featuredTag || !newTag || !favouritesTag || !exclusivesTag) return null;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        style={{ marginTop: "30px" }}
      >
        <Grid
          item
          xs={12}
          md={9}
          style={{
            position: "relative",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          {<Featured {...featuredTag} />}
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item style={{ width: "100%" }}>
              <New {...newTag} />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <New {...favouritesTag} />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <New {...exclusivesTag} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        variant="h1"
        style={{ marginTop: "60px", marginBottom: "30px" }}
      >
        Locations by Category
      </Typography>
      <Grid container spacing={2} alignItems="stretch">
        <Categories />
      </Grid>
    </>
  );
};

export default Highlights;
