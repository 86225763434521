import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as LogoIcon } from 'assets/salt_logo.svg';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  logoHeader: {
    width: '60px',
    '& path': {
      fill: palette.primary.main,
    },
    [breakpoints.up('md')]: {
      width: '100px',
    },
  },
  logoHeaderHome: {
    '& path': {
      fill: palette.common.white,
    },
  },
  logoFooter: {
    width: '75px',
    marginBottom: '75px',
    '& path': {
      fill: palette.primary.main,
    },
  },
}));

type Props = {
  homePage?: boolean;
  footer?: boolean;
};
const Logo = ({ homePage = false, footer = false }: Props) => {
  const classes = useStyles();

  return (
    <Link to="/home">
      <LogoIcon
        className={`${footer ? classes.logoFooter : classes.logoHeader} ${homePage && classes.logoHeaderHome}`}
      />
    </Link>
  );
};

export default Logo;
