import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "react-fetching-library";
import { makeStyles, Box, Button, Typography, Grid } from "@material-ui/core";
import { ArrowForward as ArrowIcon } from "@material-ui/icons";

import { fetchWork } from "resources/api";

import Template from "structure/Template";
import About from "panels/About";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    width: "calc(100% - 60px)",
    margin: "30px",
    "& h6": {
      fontSize: "20px",
    },
    "& p": {
      fontSize: "16px",
    },
    [breakpoints.up("md")]: {
      width: "calc(100% - 160px)",
      margin: "180px 80px 0 80px",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridGap: "1rem",
    },
  },

  item: {
    position: "relative",
    paddingBottom: "24px",
    height: "100%",
    "&:nth-child(1),&:nth-child(5),&:nth-child(7),&:nth-child(11),&:nth-child(13),&:nth-child(17),&:nth-child(19),&:nth-child(23)":
      {
        gridColumn: "span 2",
        gridRow: "span 2",
      },
    "&:hover a div": {
      display: "block",
    },
    [breakpoints.up("md")]: {
      paddingBottom: 0,
    },
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    border: `1px solid ${palette.common.white}`,
    "&:hover": {
      border: `1px solid ${palette.primary.main}`,
    },
  },

  title: {
    position: "absolute",
    left: "20px",
    bottom: "50px",
    textTransform: "none",
    [breakpoints.up("md")]: {
      display: "none",
      left: "24px",
      bottom: "24px",
    },
    "& p": {
      marginTop: "10px",
      lineHeight: "16px",
    },
    [breakpoints.up("md")]: {
      left: "15px",
      bottom: "15px",
      "& p": {
        lineHeight: "26px",
      },
    },
  },

  goal: {
    backgroundColor: palette.primary.main,
    padding: "100px 30px",
    marginTop: "100px",
    textAlign: "center",
    "& h2": {
      color: palette.common.white,
    },
    "& h3": {
      color: palette.common.white,
    },
    "& p": {
      color: "#99a4a0",
    },
  },

  button: {
    padding: "20px 30px",
    borderRadius: "100vh",
    marginTop: "64px",
    letterSpacing: "2.4px",
    color: palette.primary.main,
    backgroundColor: palette.common.white,
    "&.reverse": {
      color: palette.common.white,
      backgroundColor: palette.primary.main,
    },
    "& svg": {
      width: "15px",
      marginLeft: "15px",
    },
  },
}));

const Thumbs = () => {
  const classes = useStyles();

  const { payload } = useQuery(fetchWork);

  if (!payload) return null;

  return (
    <Grid container className={classes.container}>
      {payload.map(
        ({
          id,
          still_image_file,
          client_name,
          project_name,
          director_name,
          production_company,
        }) => {
          let link = `work/${id}`;
          if (client_name)
            link += `/${encodeURIComponent(
              client_name.trim().replaceAll(" ", "-")
            )}`;
          if (project_name)
            link += `/${encodeURIComponent(
              project_name.trim().replaceAll(" ", "-")
            )}`;
          if (production_company)
            link += `/${encodeURIComponent(
              production_company.trim().replaceAll(" ", "-")
            )}`;
          if (director_name)
            link += `/${encodeURIComponent(
              director_name.trim().replaceAll(" ", "-")
            )}`;
          return (
            <Grid item className={classes.item} key={id}>
              <RouterLink to={link}>
                <img
                  src={still_image_file}
                  alt="View work"
                  className={classes.image}
                />
                <Box className={classes.title}>
                  <Typography variant="subtitle1">{project_name}</Typography>
                  <Typography variant="body2">{director_name}</Typography>
                  <Typography variant="body2">{production_company}</Typography>
                </Box>
              </RouterLink>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

const Goal = () => {
  const classes = useStyles();

  return (
    <Box className={classes.goal}>
      <About title="Get in touch" copy="get_work_quote" />
      <Button
        variant="contained"
        component={RouterLink}
        to="/contact"
        className={classes.button}
      >
        Contact Us
        <ArrowIcon />
      </Button>
    </Box>
  );
};

const Work = () => (
  <Template type="work" page="work">
    <>
      <Thumbs />
      <Goal />
    </>
  </Template>
);

export default Work;
