import React, { useState, ChangeEvent, FormEvent } from "react";
import { useMutation } from "react-fetching-library";
import ReCAPTCHA from "react-google-recaptcha";
import {
  FormGroup,
  TextField,
  Box,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { ArrowForward as ArrowIcon } from "@material-ui/icons";

import { postContact } from "resources/api";
import { IContact } from "resources/interface";

import Template from "structure/Template";

import hero_1 from "assets/contact_hero/image.jpg";
import hero_2 from "assets/contact_hero/image@2x.jpg";
import hero_3 from "assets/contact_hero/image@3x.jpg";
import About from "panels/About";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  hero: {
    height: "80vh",
    width: "100vw",
    position: "relative",
    display: "block",
    overflow: "hidden !important",
    zIndex: 0,
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      objectFit: "cover",
    },
  },

  title: {
    position: "absolute",
    top: "40vh",
    left: "30px",
    "& h1": {
      fontSize: "44px",
      letterSpacing: "-0.46px",
      color: palette.common.white,
    },
    [breakpoints.up("md")]: {
      left: "80px",
    },
  },

  box: {
    padding: "60px 30px 30px 30px",
    [breakpoints.up("md")]: {
      display: "flex",
      padding: "0 80px",
    },
  },

  form: {
    [breakpoints.up("md")]: {
      padding: "60px 0",
    },
  },

  radio: {
    marginBottom: "24px",
    "& .MuiTypography-body1": {
      fontSize: "24px",
    },
  },

  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "100vh",
    },
    marginBottom: "24px",
    maxWidth: "523px",
  },

  multiline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
  },

  button: {
    width: "fit-content",
    padding: "20px 30px",
    marginTop: "24px",
    borderRadius: "100vh",
    letterSpacing: "2.4px",
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    "& svg": {
      width: "15px",
      marginLeft: "15px",
    },
  },

  touch: {
    padding: "60px 30px",
    borderTop: `1px solid ${palette.secondary.light}`,
    "& a": {
      textDecoration: "underline",
    },
    [breakpoints.up("md")]: {
      borderTop: "none",
      borderLeft: `1px solid ${palette.secondary.light}`,
      marginLeft: "60px",
    },
  },
}));

const Form = () => {
  const classes = useStyles();
  const { mutate } = useMutation(postContact);

  const [postComplete, setPostComplete] = useState("");
  const [formValues, setFormValues] = useState<IContact>({
    frmval_help: "",
    frmval_name: "",
    frmval_email: "",
    frmval_phone: "",
    frmval_comments: "",
    captchaResponse: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });

  const handleReCaptcha = (token: string | null) => {
    if (token)
      setFormValues({
        ...formValues,
        captchaResponse: token,
      });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formValues.captchaResponse !== "") {
      try {
        const { error } = await mutate(formValues);
        setPostComplete(
          error
            ? "There is a problem sending your comments/questions"
            : "Thank you for sending us your comments/questions"
        );
      } catch {
        throw new Error("Could not send contact form");
      }
    }
  };

  if (!!postComplete.length) return <Typography>{postComplete}</Typography>;

  return (
    <Box flex={4} className={classes.form}>
      <Typography variant="h2">How can we help?</Typography>

      <form onSubmit={handleSubmit}>
        <FormGroup>
          <RadioGroup
            name="frmval_help"
            onChange={handleChange}
            className={classes.radio}
          >
            <FormControlLabel
              value="Enquire about a location"
              label="Enquire about a location"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="Find a location manager"
              label="Find a location manager"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="Get a production quote"
              label="Get a production quote"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="Register a location"
              label="Register a location"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="Join our team"
              label="Join our team"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
          <TextField
            id="frmval_name"
            label="Your name"
            required
            onChange={handleChange}
            variant="outlined"
            className={classes.inputField}
          />
          <TextField
            id="frmval_phone"
            type="tel"
            label="Phone number"
            onChange={handleChange}
            variant="outlined"
            className={classes.inputField}
          />
          <TextField
            id="frmval_email"
            type="email"
            label="Email address"
            onChange={handleChange}
            variant="outlined"
            className={classes.inputField}
          />
          <TextField
            id="frmval_comments"
            label="Comments or questions"
            multiline
            rows={4}
            required
            variant="outlined"
            onChange={handleChange}
            className={`${classes.inputField} ${classes.multiline}`}
          />
          <Box display="flex" alignItems="center" justifyContent="center">
            <ReCAPTCHA
              sitekey="6LdqgxoUAAAAAIqV872Sj9F6cFxiCB5IEVx1iRmV"
              onChange={handleReCaptcha}
            />
          </Box>
          <Button type="submit" variant="contained" className={classes.button}>
            Submit
            <ArrowIcon />
          </Button>
        </FormGroup>
      </form>
    </Box>
  );
};

const Touch = () => {
  const classes = useStyles();

  return (
    <Box className={classes.touch} flex={1}>
      <About title="Get in touch" copy="contact" />
      <Box padding="16px 0">
        <a href="mailto: info@saltfilm.com">info@saltfilm.com</a>
      </Box>
      <Box>
        Location managers:
        <br />
        <a href="tel: +442076377885">+44 (0) 207 637 7885</a>
      </Box>
      <Box>
        Location library:
        <br />
        <a href="tel: +442074399344">+44 (0) 207 439 9344</a>
      </Box>
    </Box>
  );
};

const Contact = () => {
  const classes = useStyles();

  return (
    <Template type="home" page="contact">
      <>
        <div className={classes.hero}>
          <img
            src={hero_1}
            srcSet={`${hero_1} 384w, ${hero_2} 768w, ${hero_3} 1152w`}
            alt="Contact us"
          />
          <Box className={classes.title}>
            <Typography variant="h1">Contact Us</Typography>
          </Box>
        </div>
        <Box className={classes.box}>
          <Form />
          <Touch />
        </Box>
      </>
    </Template>
  );
};

export default Contact;
