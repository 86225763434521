import React, { useState, ReactElement, FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-fetching-library";
import { Container } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import { fetchContent } from "resources/api";
import { PAGES } from "resources/constants";

import { ContentContext, defaultContent } from "./Context";
import AppBar from "./AppBar";
import Menu from "./Menu";
import Footer from "./Footer";

export const colourMain = "#193027";
//const colourDark = '#172823';
export const softGrey = "#f4f4f4";
const midGrey = "#707070";

const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    primary: {
      main: colourMain,
      //      dark: colourDark,
    },
    secondary: {
      main: midGrey,
      light: softGrey,
    },
  },
  typography: {
    fontFamily: "DM Sans",
    h1: {
      fontSize: "24px",
      fontWeight: 500,
      letterSpacing: "-0.08px",
      lineHeight: "40px",
      [breakpoints.up("md")]: {
        fontSize: "28px",
      },
    },
    h2: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "2px",
      lineHeight: "20px",
      color: "#000",
      textTransform: "uppercase",
      marginBottom: "40px",
      [breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    h3: {
      fontSize: "22px",
      fontWeight: 500,
      lineHeight: "34px",
      color: "#202020",
      marginBottom: "24px",
      [breakpoints.up("md")]: {
        fontSize: "28px",
        lineHeight: "42px",
      },
    },
    h4: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#000",
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "26px",
      color: "#fff",
      [breakpoints.up("md")]: {
        fontSize: "28px",
      },
    },
    subtitle2: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "30px",
      color: "#000",
    },
    body1: {
      fontSize: "17px",
      lineHeight: "30px",
      color: midGrey,
    },
    body2: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "26px",
      color: "#fff",
      [breakpoints.up("md")]: {
        fontSize: "24px",
      },
    },
    caption: {
      fontSize: "12px",
      lineHeight: "16px",
      color: midGrey,
    },
  },
  overrides: {
    MuiAccordionDetails: {
      root: {
        padding: "15px 30px",
        marginTop: "-50px",
      },
    },
    MuiCssBaseline: {
      "@global": {
        a: {
          color: colourMain,
          textDecoration: "none",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#fff",
        padding: "24px 16px 16px",
        borderRadius: "4px",
        boxShadow: "0 6px 8px 0 rgba(44, 41, 42, 0.12)",
        border: "solid 1px #e1e1e1",
      },
      arrow: {
        fontSize: "20px",
        "&::before": {
          backgroundColor: "#fff",
          border: "solid 1px #e1e1e1",
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "-webkit-fill-available;min-height:100vh",
    fontSize: "17px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    overflowX: "hidden",
  },
  main: {
    padding: "0",
    maxWidth: "none",
  },
}));

interface IProps {
  type: string;
  page: string;
  title?: string;
  description?: string;
  children: ReactElement;
}
const Template: FunctionComponent<IProps> = ({
  type,
  page,
  title,
  description,
  children,
}) => {
  const { payload } = useQuery(fetchContent);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <ContentContext.Provider
        value={{ contentContext: payload || defaultContent }}
      >
        <div className={classes.root}>
          <Helmet>
            <title>{title || PAGES[page].title} | Salt Film</title>
            <link rel="canonical" href={`https://saltfilm.com/${page}`} />
            <meta
              name="description"
              content={description || PAGES[page].description}
            />
          </Helmet>
          <CssBaseline />
          <Menu isOpen={mobileOpen} handleClick={handleDrawerToggle} />
          <AppBar
            pageType={type}
            handleClick={handleDrawerToggle}
            title={title || PAGES[page].title}
          />
          <Container component="main" className={classes.main} maxWidth="xl">
            {children}
          </Container>
          <Footer />
        </div>
      </ContentContext.Provider>
    </ThemeProvider>
  );
};

export default Template;
