import { Reducer } from "react";

import { ILocationContext, IAction, ILocation } from "./interface";
import { ActionType } from "./constants";

export const reducer: Reducer<ILocationContext, IAction> = (
  { basketList, tagList },
  action
) => {
  switch (action.type) {
    case ActionType.addToBasket: {
      if (!action.selectedLocation) return { basketList, tagList };

      let newList;
      const { id, images } = action.selectedLocation;
      const { images: existingImages } =
        basketList.find((location) => location.id === id) || {};
      if (images && existingImages) {
        newList = basketList.filter((location) => location.id !== id);
        const newImages = [
          ...existingImages,
          ...images.filter(
            (image) =>
              !existingImages.find(
                (exImage) => exImage.ext_itemps_id === image.ext_itemps_id
              )
          ),
        ];
        newList = [
          ...newList,
          Object.assign({}, action.selectedLocation, { images: newImages }),
        ];
      } else {
        newList = [...basketList, action.selectedLocation];
      }

      localStorage.setItem("basketList", JSON.stringify(newList));
      return { basketList: newList, tagList };
    }

    case ActionType.removeFromBasket: {
      if (action.selectedLocationId) {
        const newList = basketList.filter(
          (location) => location.id !== action.selectedLocationId
        );
        localStorage.setItem("basketList", JSON.stringify(newList));
        return { basketList: newList, tagList };
      }

      if (action.selectedImageId) {
        let foundLocation: ILocation | undefined;
        let newList = basketList.filter((location) => {
          if (
            !foundLocation &&
            location.images?.find(
              (image) => image.ext_itemps_id === action.selectedImageId
            )
          ) {
            foundLocation = location;
            return false;
          }
          return true;
        });
        if (foundLocation) {
          const newImageList =
            foundLocation.images?.filter(
              (image) => image.ext_itemps_id !== action.selectedImageId
            ) || [];
          if (newImageList.length) {
            newList = [
              ...newList,
              Object.assign({}, foundLocation, {
                images: newImageList,
              }),
            ];
          }
        }
        localStorage.setItem("basketList", JSON.stringify(newList));
        return { basketList: newList, tagList };
      }

      return { basketList, tagList };
    }

    case ActionType.clearBasket: {
      localStorage.removeItem("basketList");
      return { basketList: [], tagList };
    }

    case ActionType.addFilterTag: {
      if (!action.selectedTag) return { basketList, tagList };

      const { id, filter_item_title } = action.selectedTag;

      if (id === "LondonAll" && action.allTags) {
        action.allTags
          .filter(({ filter_item_title }) =>
            filter_item_title.startsWith("London")
          )
          .forEach((tag) => {
            tagList.push(tag);
          });
      } else if (
        id &&
        filter_item_title &&
        !tagList.find(
          (tag) =>
            (id === "postcode" &&
              filter_item_title === tag.filter_item_title) ||
            (id !== "postcode" && id === tag.id)
        )
      ) {
        tagList.push(action.selectedTag);
      }

      localStorage.setItem("tagList", JSON.stringify(tagList));
      return { basketList, tagList };
    }

    case ActionType.addFilterTags: {
      if (!action.selectedTags) return { basketList, tagList };

      action.selectedTags.forEach((selectedTag) => {
        const { id, filter_item_title } = selectedTag;

        if (id === "LondonAll" && action.allTags) {
          action.allTags
            .filter(({ filter_item_title }) =>
              filter_item_title.startsWith("London")
            )
            .forEach((tag) => {
              tagList.push(tag);
            });
        } else if (
          id &&
          filter_item_title &&
          !tagList.find(
            (tag) =>
              (id === "postcode" &&
                filter_item_title === tag.filter_item_title) ||
              (id !== "postcode" && id === tag.id)
          )
        ) {
          tagList.push(selectedTag);
        }
      });

      localStorage.setItem("tagList", JSON.stringify(tagList));
      return { basketList, tagList };
    }

    case ActionType.removeFilterTag: {
      if (!action.selectedTag) return { basketList, tagList };

      const { id, filter_item_title } = action.selectedTag;
      let newList = tagList;
      if (id) {
        newList = tagList.filter(
          (tag) =>
            (id === "postcode" &&
              filter_item_title !== tag.filter_item_title) ||
            (id !== "postcode" && tag.id !== id)
        );
        localStorage.setItem("tagList", JSON.stringify(newList));
      }
      return { basketList, tagList: newList };
    }

    case ActionType.removeAllFilters: {
      localStorage.removeItem("tagList");
      return { basketList, tagList: [] };
    }

    default:
      return { basketList, tagList };
  }
};
