import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Instagram as InstagramIcon } from '@material-ui/icons';
import { useEffect } from 'react';

const useStyles = makeStyles(({ breakpoints }) => ({
  insta: {
    padding: '30px',
    '& svg': {
      verticalAlign: 'sub',
      marginRight: '20px',
    },
    '& p': {
      marginBottom: '30px',
    },
    [breakpoints.up('md')]: {
      padding: '80px',
    },
  },

  image: {
    objectFit: 'cover',
    width: '100%',
    height: '75vw',
    [breakpoints.up('md')]: {
      height: '21vw',
    },
  },
}));

interface IProps {
  limit?: number;
}
const Instagram = ({ limit = 9 }: IProps) => {
  const classes = useStyles();
  // const { payload } = useQuery(fetchInsta);

  // if (!payload || !payload.data) return null;

  // const images = payload.data.reduce((temp: string[], { media_type, media_url }) => {
  //   if (temp.length < limit && (media_type === 'IMAGE' || media_type === 'CAROUSEL_ALBUM')) temp.push(media_url);
  //   return temp;
  // }, []);

  useEffect(() => {
    (function (d, s, id) {
      var js;
      if (d.getElementById(id)) {
        d.getElementById(id)?.remove();
      }
      js = d.createElement(s);
      js.id = id;
      (js as HTMLScriptElement).src = 'https://embedsocial.com/cdn/ht.js';
      d.getElementsByTagName('head')[0].appendChild(js);
    })(document, 'script', 'EmbedSocialHashtagScript');
  }, []);

  return (
    <>
      <Box className={classes.insta}>
        <Typography variant="h3">
          <InstagramIcon />
          Follow us on Instagram
        </Typography>
        <Typography>
          Stay up to date with the latest locations added to our library as well as sneak peaks from behind the scenes
          at our most recent shoots.
        </Typography>
        {/*<Grid container spacing={2} style={{ marginTop: '30px' }}>
          {images.map((url, index) => (
            <Grid item xs={12} md={3} key={index}>
              <a href="https://www.instagram.com/saltfilm_locations/" target="_blank" rel="noopener noreferrer">
                <img src={url} alt="View on Instagram" title="View on Instagram" className={classes.image} />
              </a>
            </Grid>
          ))}
          </Grid>*/}
        <div
          className="embedsocial-hashtag"
          data-ref={
            limit === 9 ? 'fd153e9f1302d707d877f83d46019dfe6edad0aa' : '341555f337fc991bc9e49cbe78a30d3bb120f067'
          }
        ></div>
      </Box>
    </>
  );
};

export default Instagram;
