import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";

import Logo from "./Logo";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  footer: {
    padding: "64px 30px 154px 30px",
    backgroundColor: palette.secondary.light,
    [breakpoints.up("md")]: {
      paddingLeft: "80px",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box component="footer" className={classes.footer}>
      <Grid container spacing={3}>
        <Grid item xs={12} md>
          <Logo footer />
        </Grid>
        <Grid item xs={6} md>
          <Box>
            <Typography color="textPrimary">Copyright</Typography>
          </Box>
          <Box>
            <Typography variant="caption">
              Salt Film Ltd
              <br />
              All rights reserved
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md>
          <Box>
            <Typography color="textPrimary">Address</Typography>
          </Box>
          <Box>
            <Typography variant="caption">
              Salt Film
              <br />
              22a Gascony Avenue
              <br />
              London, NW6 4NA
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md>
          <Box>
            <Typography color="textPrimary">Info</Typography>
          </Box>
          <Box>
            <Typography variant="caption">
              <a href="mailto: info@saltfilm.com">info@saltfilm.com</a>
              <br />
              Location managers:
              <br />
              <a href="tel: +442076377885">+44 (0) 207 637 7885</a>
              <br />
              Location library:
              <br />
              <a href="tel: +442074399344">+44 (0) 207 439 9344</a>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md>
          <Box>
            <Typography color="textPrimary">Social</Typography>
          </Box>
          <Box>
            <Typography variant="caption">
              <a
                href="https://www.instagram.com/saltfilm_locations/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              <br />
              <a
                href="https://www.facebook.com/saltfilm"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
