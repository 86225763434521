import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "react-fetching-library";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ArrowForward as ArrowIcon } from "@material-ui/icons";

import { fetchWork } from "resources/api";
import Template, { softGrey } from "structure/Template";
import About from "panels/About";
import Testimonials from "panels/Testimonials";

import hero_1 from "assets/production_hero/image.jpg";
import hero_2 from "assets/production_hero/image@2x.jpg";
import hero_3 from "assets/production_hero/image@3x.jpg";
import stills_1_1 from "assets/production_stills_1/image.jpg";
import stills_1_2 from "assets/production_stills_1/image@2x.jpg";
import stills_1_3 from "assets/production_stills_1/image@3x.jpg";
import stills_2_1 from "assets/production_stills_2/image.jpg";
import stills_2_2 from "assets/production_stills_2/image@2x.jpg";
import stills_2_3 from "assets/production_stills_2/image@3x.jpg";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  hero: {
    height: "80vh",
    width: "100vw",
    position: "relative",
    display: "block",
    overflow: "hidden !important",
    zIndex: 0,
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      objectFit: "cover",
    },
  },
  title: {
    position: "absolute",
    top: "35vh",
    left: "30px",
    "& h1": {
      fontSize: "44px",
      letterSpacing: "-0.46px",
      color: palette.common.white,
    },
    [breakpoints.up("md")]: {
      left: "80px",
    },
  },

  content: {
    marginLeft: "30px",
    [breakpoints.up("md")]: {
      marginLeft: "80px",
    },
  },

  about: {
    padding: "30px 30px 30px 0",
    [breakpoints.up("md")]: {
      padding: "80px 20% 0 10%",
    },
  },

  bg: {
    backgroundColor: softGrey,
  },

  stills: {
    paddingRight: "30px",
    [breakpoints.up("md")]: {
      padding: "90px 80px",
    },
  },

  insertImage: {
    width: "calc(100vw - 30px)",
    [breakpoints.up("md")]: {
      width: "46.9vw",
      margin: "0px 80px 0 -80px",
    },
  },

  goal: {
    backgroundColor: palette.primary.main,
    padding: "100px 30px",
    textAlign: "center",
    marginTop: "10px",
    "& h2": {
      color: palette.common.white,
    },
    "& h3": {
      color: palette.common.white,
      [breakpoints.up("md")]: {
        maxWidth: "650px",
        margin: "0 auto",
      },
    },
    "& p": {
      color: "#99a4a0",
    },
  },

  button: {
    padding: "20px 30px",
    borderRadius: "100vh",
    marginTop: "64px",
    letterSpacing: "2.4px",
    color: palette.primary.main,
    backgroundColor: palette.common.white,
    "&.reverse": {
      color: palette.common.white,
      backgroundColor: palette.primary.main,
    },
    "& svg": {
      width: "15px",
      marginLeft: "15px",
    },
  },
}));

const Production = () => {
  const classes = useStyles();

  return (
    <Box className={classes.about}>
      <About copy="production_services" />
    </Box>
  );
};

const productionWork = [87475, 87439, 85710, 13381591, 13381592, 13381593];

const Work = () => {
  const classes = useStyles();
  const { payload } = useQuery(fetchWork);
  if (!payload) return null;

  return (
    <Box className={classes.stills}>
      <Typography variant="h2">Selected Productions</Typography>
      <Box>
        {productionWork.map((selectedId) => {
          const work = payload.find(({ id }) => id === selectedId.toString());
          if (!work) return null;

          let link = `work/${selectedId}`;
          if (work.client_name)
            link += `/${encodeURIComponent(
              work.client_name.trim().replaceAll(" ", "-")
            )}`;
          if (work.project_name)
            link += `/${encodeURIComponent(
              work.project_name.trim().replaceAll(" ", "-")
            )}`;
          if (work.production_company)
            link += `/${encodeURIComponent(
              work.production_company.trim().replaceAll(" ", "-")
            )}`;
          if (work.director_name)
            link += `/${encodeURIComponent(
              work.director_name.trim().replaceAll(" ", "-")
            )}`;

          return (
            <Box mb={4}>
              <RouterLink to={link} key={selectedId}>
                <img
                  src={work.still_image_file}
                  alt={work.project_name}
                  style={{ width: "100%" }}
                />
              </RouterLink>
              <Typography variant="subtitle2">{work.project_name}</Typography>
              <Typography variant="h4">{work.director_name}</Typography>
              <Typography variant="h4">{work.production_company}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const Goal = () => {
  const classes = useStyles();

  return (
    <Box className={classes.goal}>
      <About title="Get a production quote" copy="get_production_quote" />
      <Button
        variant="contained"
        component={RouterLink}
        to="/contact"
        className={classes.button}
      >
        Shoot with Salt
        <ArrowIcon />
      </Button>
    </Box>
  );
};

const ProductionServices = () => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Template type="home" page="production">
      <>
        <div className={classes.hero}>
          <img
            src={hero_1}
            srcSet={`${hero_1} 384w, ${hero_2} 768w, ${hero_3} 1152w`}
            alt="Location-led production services"
          />
          <Box className={classes.title}>
            <Typography variant="h1" style={{ lineHeight: "55px" }}>
              Location Led
              <br />
              Production Services
            </Typography>
          </Box>
        </div>
        <Box className={classes.content}>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} md={7}>
              <Production />
            </Grid>
            <Grid item xs={12} md={5}>
              <Work />
            </Grid>
          </Grid>
        </Box>
        {desktop ? (
          <Box className={classes.bg}>
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              style={{ marginTop: "100px" }}
            >
              <Grid item xs={12} md={6}>
                <img
                  src={stills_1_1}
                  srcSet={`${stills_1_1} 384w, ${stills_1_2} 768w, ${stills_1_3} 1152w`}
                  alt="Film & commercials production"
                  style={{
                    width: "66%",
                    maxWidth: "500px",
                    marginTop: "-100px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box style={{ padding: "90px 0", marginRight: "80px" }}>
                  <About
                    title="Film & commercials production"
                    copy="film_commercials_production"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            className={classes.bg}
            style={{ padding: "80px 30px 30px 30px" }}
          >
            <About
              title="Film & commercials production"
              copy="film_commercials_production"
              image={
                <img
                  src={stills_1_1}
                  srcSet={`${stills_1_1} 384w, ${stills_1_2} 768w, ${stills_1_3} 1152w`}
                  alt="Film & commercials production"
                  style={{ marginLeft: "-30px" }}
                />
              }
            />
          </Box>
        )}
        <Testimonials />
        {desktop ? (
          <Box className={classes.bg}>
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              style={{ marginTop: "200px" }}
            >
              <Grid item xs={12} md={6}>
                <Box className={classes.stills}>
                  <About title="Stills production" copy="stills_production" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                <img
                  src={stills_2_1}
                  srcSet={`${stills_2_1} 384w, ${stills_2_2} 768w, ${stills_2_3} 1152w`}
                  alt="Stills production"
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                    marginTop: "-100px",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            className={classes.bg}
            style={{ padding: "80px 30px 30px 30px" }}
          >
            <About
              title="Stills production"
              copy="stills_production"
              image={
                <img
                  src={stills_2_1}
                  srcSet={`${stills_2_1} 384w, ${stills_2_2} 768w, ${stills_2_3} 1152w`}
                  alt="Stills production"
                  style={{ marginLeft: "-30px" }}
                />
              }
            />
          </Box>
        )}
        <Goal />
      </>
    </Template>
  );
};

export default ProductionServices;
