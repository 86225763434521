import React, { FunctionComponent, useState } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';

import { ReactComponent as EmailIcon } from 'assets/icons/mail-icon.svg';
import EmailForm from 'tags/EmailForm';

const useStyles = makeStyles(({ palette }) => ({
  box: {
    position: 'relative',
    width: '60px',
    textAlign: 'center',
  },

  icon: {
    color: palette.common.white,
    marginLeft: '-10px',
    '&::before': {
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      paddingBottom: '100%',
      backgroundColor: palette.primary.main,
      content: '""',
      position: 'absolute',
    },
    '& svg': {
      fill: palette.common.white,
      width: '25px',
      height: '25px',
      zIndex: 2,
    },
  },

  text: {
    fontSize: '9px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '0.9px',
    whiteSpace: 'nowrap',
  },
}));

interface IProps {
  id: string;
}
const EmailLink: FunctionComponent<IProps> = ({ id }) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);

  return (
    <Box className={classes.box}>
      <IconButton
        edge="end"
        className={classes.icon}
        onClick={() => setShowForm(!showForm)}
        //        href={`mailto:info@saltfilm.com&subject=Salt Location #${id}&body=I'm interested in the following Salt Location: ${process.env.REACT_APP_HOST}/location/${id}`}
      >
        <EmailIcon />
      </IconButton>
      <Typography className={classes.text}>Email link</Typography>
      {showForm && <EmailForm action={() => setShowForm(false)} id={id} />}
    </Box>
  );
};

export default EmailLink;
