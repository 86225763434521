import React, { ReactNode, createContext, useReducer, Dispatch } from "react";
import {
  createClient,
  ClientContextProvider,
  RequestInterceptor,
  ResponseInterceptor,
  createCache,
  QueryResponse,
} from "react-fetching-library";

import { ITag, ILocation, IAction, IContent, ITeam } from "resources/interface";
import { reducer } from "resources/reducer";

const requestHostInterceptor: (host: string) => RequestInterceptor =
  (host) => () => async (action) => {
    if (action.endpoint.includes("https")) return action;

    return {
      ...action,
      endpoint: `${host}${
        action.endpoint.includes("MzCms_Overrides")
          ? ""
          : process.env.REACT_APP_API_FOLDER
      }${action.endpoint}`,
    };
  };

const responseInterceptor: ResponseInterceptor =
  (client) => async (action, response) => {
    if (response.payload && response.payload.length) {
      if (response.payload[0].filter_item_title) {
        return {
          ...response,
          payload: [
            ...response.payload
              .filter((a: ITag) => /^(London).*/.test(a.filter_item_title))
              .sort((a: ITag, b: ITag) =>
                a.filter_item_title > b.filter_item_title ? 1 : -1
              ),
            ...response.payload
              .filter((a: ITag) => /^(?!London).*/.test(a.filter_item_title))
              .filter((a: ITag) => /^(?![0-9]).*/.test(a.filter_item_title))
              .sort((a: ITag, b: ITag) =>
                a.filter_item_title > b.filter_item_title ? 1 : -1
              ),
            ...response.payload
              .filter((a: ITag) => /^[0-9]+/.test(a.filter_item_title))
              .sort((a: ITag, b: ITag) =>
                a.filter_item_title > b.filter_item_title ? 1 : -1
              ),
          ],
        };
      }
      if (response.payload[0].member_image) {
        return {
          ...response,
          payload: response.payload.map((team: ITeam) => {
            if (team.member_image.includes("placeholder.jpg")) {
              return {
                ...team,
                member_image: "/headshot_placeholder.jpg",
              };
            }
            return team;
          }),
        };
      }
    }

    return response;
  };

const cache = createCache<QueryResponse<any>>(
  (action) => {
    return action.method === "GET";
  },
  () => true
);

const client = createClient({
  requestInterceptors: [
    requestHostInterceptor(`${process.env.REACT_APP_HOST}`),
  ],
  responseInterceptors: [responseInterceptor],
  cacheProvider: cache,
});

const defaultBasket: Array<ILocation> = JSON.parse(
  localStorage.getItem("basketList") || "[]"
);
const defaultTags: Array<ITag> = JSON.parse(
  localStorage.getItem("tagList") || "[]"
);
const defaultDispatch: Dispatch<IAction> = () => [];

export const LocationContext = createContext({
  locationContext: { basketList: defaultBasket, tagList: defaultTags },
  dispatch: defaultDispatch,
});

export const defaultContent: Array<IContent> = [];
export const ContentContext = createContext({ contentContext: defaultContent });

interface IProps {
  children: ReactNode;
}
export const ContextProvider = ({ children }: IProps) => {
  const [locationContext, dispatch] = useReducer(reducer, {
    basketList: defaultBasket,
    tagList: defaultTags,
  });

  return (
    <ClientContextProvider client={client}>
      <LocationContext.Provider value={{ locationContext, dispatch }}>
        {children}
      </LocationContext.Provider>
    </ClientContextProvider>
  );
};
