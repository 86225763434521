import React from 'react';
import { useQuery } from 'react-fetching-library';
import { Grid } from '@material-ui/core';

import { fetchLogos } from 'resources/api';

const Logos = () => {
  const { payload } = useQuery(fetchLogos);

  if (!payload) return null;

  return (
    <Grid container spacing={2}>
      {payload.map(({ id, slider_image_file }) => (
        <Grid item xs={6} md={2}>
          <img key={id} alt="Client" src={slider_image_file} width="100%" />
        </Grid>
      ))}
    </Grid>
  );
};

export default Logos;
