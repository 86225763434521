import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  IconButton,
  Typography,
  FormControl,
} from "@material-ui/core";
import { ExpandMore as ExpandIcon } from "@material-ui/icons";

import WhereTags from "tags/Where";
import WhatTags from "tags/What";

import { ReactComponent as CloseIcon } from "assets/icons/cross-icon.svg";
import IDSearch from "./IDSearch";

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  drawer: {
    [breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },

  drawerPaper: {
    width: "100%",
    backgroundColor: palette.common.white,
  },

  appbar: {
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "inset 0 -1px 0 0 #e1e1e1, inset 0 1px 0 0 #e1e1e1",
    padding: "13px 30px",
  },

  title: {
    marginTop: "10px",
  },

  close: {
    color: palette.primary.main,
    "& svg": {
      width: "30px",
      height: "30px",
    },
  },

  accordion: {
    padding: "16px 30px",
  },
}));

interface IProps {
  isOpen: boolean;
  handleClick: () => void;
}

const Filters = ({ isOpen, handleClick }: IProps) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={isOpen}
      onClose={handleClick}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Box className={classes.appbar}>
        <Typography variant="subtitle2" className={classes.title}>
          Filters
        </Typography>
        <IconButton
          edge="end"
          aria-label="menu"
          onClick={handleClick}
          className={classes.close}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordion}
        >
          <Typography variant="h4">Areas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WhereTags handleClick={handleClick} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.accordion}
        >
          <Typography variant="h4">Location Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WhatTags handleClick={handleClick} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={classes.accordion}
        >
          <Typography variant="h4">Search by Location ID</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <IDSearch style="mobile" />
        </AccordionDetails>
      </Accordion>
    </Drawer>
  );
};

export default Filters;
