import React, { forwardRef, Ref, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Box,
} from "@material-ui/core";

import { ITag } from "resources/interface";
import { useLocationContext } from "resources/hooks";
import { ActionType } from "resources/constants";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F4F4F4",
  },

  fgBox: {
    backgroundColor: "#E6E9E8",
    margin: "5px",
    padding: "20px",
  },

  txt: {
    fontSize: "14px",
  },
}));

interface IProps {
  action(): void;
}
const Help = forwardRef(({ action }: IProps, ref: Ref<any>) => {
  const classes = useStyles();
  const { dispatch } = useLocationContext();

  const onDrop = useCallback((acceptedFiles) => {
    chooseFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 10485760,
  });

  const chooseFile = (files: FileList | null) => {
    if (files && files.length && files[0].size <= 10485760) {
      const tag: ITag = {
        id: "%image",
        filter_item_title: files[0].name,
        data: files[0],
      };

      dispatch({
        type: ActionType.addFilterTag,
        selectedTag: tag,
      });
      action();
    }
  };

  return (
    <Dialog
      open={true}
      onClose={action}
      maxWidth={false}
      fullWidth
      PaperProps={{ style: { height: "100%" } }}
      ref={ref}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h3" style={{ margin: "0" }}>
          How to Search the Salt Library
        </Typography>
        <IconButton aria-label="close" onClick={action} style={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box style={{ padding: "20px" }}>
          <Typography>
            Salt's extensive library covers every location you can possibly
            imagine. If you like a location, save it to your basket. To help you
            find the perfect location, we have a variety of search options.
            Here's a quick guide on how to use them:
          </Typography>
        </Box>
        <Box className={classes.fgBox}>
          <Typography variant="h4" style={{ fontSize: "18px" }}>
            1. Type in a keyword…
          </Typography>
          <Typography className={classes.txt}>
            If you know exactly what you want, type it in here. As you type, a
            dropdown list of matching tags will appear. Select one and just
            press enter.
          </Typography>
        </Box>
        <Box className={classes.fgBox}>
          <Typography variant="h4" style={{ fontSize: "18px" }}>
            2. Area / Postcode
          </Typography>
          <Typography className={classes.txt}>
            Do you need your location to be in a specific area? Enter the first
            part of the postcode and click it on the dropdown list. You can only
            enter one postcode at a time. Or type in the area name and press
            enter, or choose the area from the dropdown list.
          </Typography>
        </Box>
        <Box className={classes.fgBox}>
          <Typography variant="h4" style={{ fontSize: "18px" }}>
            3. AI Image Search
          </Typography>
          <Typography className={classes.txt}>
            Click the AI Image Search option and you'll see a pop-up box that
            allows you to drag and drop a picture of the type of location you're
            looking for. The AI Image Search will return all the locations in
            the database with similar features. If you are working to a very
            particular picture reference, this is a great way of searching.{" "}
          </Typography>
        </Box>
        <Box className={classes.fgBox}>
          <Typography variant="h4" style={{ fontSize: "18px" }}>
            4. Search Filters
          </Typography>
          <Typography className={classes.txt}>
            Click the Search Filters option to see a pop up with all of our
            categories and related tags. This is a great way of searching if you
            have a lot of search criteria because you'll be able to see the
            number of matching locations next to each tag, allowing you to
            either narrow or widen your search before scrolling through
            individual listings.
            <ul>
              <li>
                <b>Categories</b>
                <br />
                Start on the left by choosing a category. You can choose more
                than one if you want to. Simply click on the category name and
                your selection will turn green. The number on the right is the
                number of locations in that category. Click it again to remove
                your selection.
              </li>
              <li>
                <b>Geographical Area</b>
                <br />
                In this column you're also welcome to choose as many areas as
                you like, in your search criteria.
              </li>
              <li>
                <b>Type</b>
                <br />
                Choose as many Types as you like. For example, if you're looking
                to film in a lounge, you might want to search Houses as well as
                Flats / Apartments.
              </li>
              <li>
                <b>Architectural Period / Style</b>
                <br />
                These filters relate to exterior features and are best searched
                one at a time.
                <br />
                If you choose more than one, the search will only offer up
                locations tagged with both filters, instead of searching both
                filters individually.
              </li>
              <li>
                <b>Description / Look</b>
                <br />
                These filters relate to interior features and are best searched
                one at a time
                <br />
                If you choose more than one, the search will only offer up
                locations tagged with both filters, instead of searching both
                filters individually.
              </li>
              <li>
                <b>Space / Feature</b>
                <br />
                These filters relate to both interior and exterior spaces and
                features and cover everything from fireplaces to treehouses.
                <br />
                Search them individually or together depending on how specific
                your brief is. For example, if you know that you're looking for
                a house location with a garden, kitchen and attic then choose
                all three filters and the search results will contain only those
                locations that match all three criteria.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box className={classes.fgBox}>
          <Typography variant="h4" style={{ fontSize: "18px" }}>
            5. Map Search
          </Typography>
          <Typography className={classes.txt}>
            Similar to the Area / Postcode Search, this option lets you choose a
            wider, but still specific area of London to limit your search to.
            You can even choose multiple areas if you want to.
          </Typography>
        </Box>
        <Box className={classes.fgBox}>
          <Typography variant="h4" style={{ fontSize: "18px" }}>
            6. Location ID
          </Typography>
          <Typography className={classes.txt}>
            Know exactly which location you're looking for? Type the Location ID
            here and press enter.
          </Typography>
        </Box>
        <Box style={{ padding: "20px" }}>
          <Typography>
            Once you're scrolling through your search results, you can save
            favourite locations to your basket in two ways:
            <ol>
              <li>
                <b>Add entire location to basket</b>
                <br />
                You'll find this option beneath the summary view thumbnails of a
                listing, before clicking through to see all its images. Click
                this option if you'd like to add the entire listing to your
                basket.
              </li>
              <li>
                <b>Add image to basket</b>
                <br />
                If you only want a handful of specific images saved to your
                basket, you can click through into the full listing and click
                the '+ Add image to basket' beneath each thumbnail that you'd
                like to include.
              </li>
            </ol>
            Once you've made your selects click on the basket icon in the top
            right-hand corner to see an overview of your favourites. You can
            remove any that aren't quite right. You can download individual
            images or entire locations to Dropbox. You can also download all
            images and locations in your basket by clicking on the 'Download'
            button at the top of the listings. This will separate them into
            individual folders containing the images you've selected. You can't
            share your basket but your locations will remain in your basket
            until you clear them.
          </Typography>
        </Box>
        <Typography style={{ margin: "20px" }}>
          Need more help? Please <Link to="/contact">contact us</Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
});

export default Help;
