import React from "react";
import { Container } from "@material-ui/core";

import { errorMessages } from "resources/constants";
import Template from "structure/Template";

interface IProps {
  text: string;
}

const Error = ({ text }: IProps) => {
  return (
    <Template type="error" page="not-found.aspx">
      <Container maxWidth="md">
        <h1>{errorMessages[text].title}</h1>
        <p>{errorMessages[text].description}</p>
        <p>
          <a rel="noopener noreferrer" href="/home">
            <button>Salt homepage</button>
          </a>
        </p>
      </Container>
    </Template>
  );
};

export default Error;
