import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Dialog,
  makeStyles,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AddCircle as AddIcon, Close as RemoveIcon } from "@material-ui/icons";

import { useLocationContext } from "resources/hooks";
import { ActionType } from "resources/constants";
import { ILocation, ILocationImage } from "resources/interface";

import CarouselImages from "library/CarouselImages";

//import { ReactComponent as AddIcon } from 'assets/icons/plus-icon.svg';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  button: {
    width: "fit-content",
    borderRadius: 0,
  },

  icon: {
    width: "20px",
    height: "20px",
    marginRight: "5px",
    color: palette.primary.main,
  },

  image: {
    width: "100%",
    [breakpoints.up("md")]: {
      height: "19.2vw",
      objectFit: "cover",
    },
  },

  overlay: {
    opacity: 0.5,
  },
}));

interface IProps {
  location: ILocation;
  images: ILocationImage[];
}
const Images = ({ location, images }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { dispatch, locationContext } = useLocationContext();
  const { basketList } = locationContext || {};
  const basketImages = basketList.find(({ id }) => id === location.id);

  const [showCarousel, setShowCarousel] = useState(-1);

  if (!images || !Array.isArray(images)) return null;

  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        {images.map(
          ({ ext_itemps_id, ext_itemps_product_set_image }, index) => {
            const inBasket =
              basketImages &&
              basketImages.images?.some(
                (image) => image.ext_itemps_id === ext_itemps_id
              );
            return (
              <Grid item xs={12} md={4} key={ext_itemps_id}>
                <Box display="flex" flexDirection="column">
                  {images && images.length && (
                    <>
                      <img
                        src={ext_itemps_product_set_image}
                        alt="View location"
                        onClick={() => (matches ? setShowCarousel(index) : {})}
                        className={`${classes.image} ${
                          inBasket && classes.overlay
                        }`}
                      />
                      {inBasket ? (
                        <IconButton
                          aria-label="remove image from basket"
                          onClick={() =>
                            dispatch({
                              type: ActionType.removeFromBasket,
                              selectedImageId: ext_itemps_id,
                            })
                          }
                          className={classes.button}
                        >
                          <RemoveIcon className={classes.icon} />
                          <Typography color="primary">
                            Remove image from basket
                          </Typography>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="add to basket"
                          onClick={() =>
                            dispatch({
                              type: ActionType.addToBasket,
                              selectedLocation: Object.assign({}, location, {
                                images: [
                                  {
                                    ext_itemps_id,
                                    ext_itemps_product_set_image,
                                  },
                                ],
                              }),
                            })
                          }
                          className={classes.button}
                        >
                          <AddIcon className={classes.icon} />
                          <Typography color="primary">
                            Add image to basket
                          </Typography>
                        </IconButton>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            );
          }
        )}
      </Grid>
      <Dialog
        open={showCarousel >= 0}
        onClose={() => setShowCarousel(-1)}
        maxWidth="lg"
      >
        <CarouselImages images={images} init={showCarousel} />
      </Dialog>
    </>
  );
};

export default Images;
