import React, { useState } from "react";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";

import Help from "tags/Help";

const useStyles = makeStyles(({ palette }) => ({
  box: {
    display: "flex",
    flexDirection: "row-reverse",
    height: "30px",
    width: "30%",
    gap: "10px",
    marginTop: "24px",
    cursor: "pointer",
  },

  label: {
    color: "#000",
    fontSize: "14px",
  },

  help: {
    border: "none",
    outline: "none",
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    marginTop: "2px",
    backgroundColor: "#99A4A0",
    "&:hover": {
      backgroundColor: "#193027",
    },
  },
}));

const SearchHelp = () => {
  const classes = useStyles();

  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <Box className={classes.box} onClick={() => setShowPopup(true)}>
        <IconButton className={classes.help}>
          <Typography style={{ color: "#fff", lineHeight: 0 }}>?</Typography>
        </IconButton>
        <Typography className={classes.label}>How to Search</Typography>
      </Box>
      {showPopup && <Help action={() => setShowPopup(false)} />}
    </>
  );
};

export default SearchHelp;
