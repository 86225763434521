import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArrowForward as ArrowIcon,
  VolumeOffOutlined as UnmuteIcon,
} from "@material-ui/icons";

import Template from "structure/Template";
import About from "panels/About";
import InstagramCarousel from "panels/Instagram";
import { TeamCarousel, TeamGallery } from "panels/Team";

import hero_3 from "assets/home_hero/image@3x.jpg";
import what_we_do_1 from "assets/home_what_we_do/image.jpg";
import what_we_do_2 from "assets/home_what_we_do/image@2x.jpg";
import what_we_do_3 from "assets/home_what_we_do/image@3x.jpg";
import location_managers_1 from "assets/home_location_managers/image.jpg";
import location_managers_2 from "assets/home_location_managers/image@2x.jpg";
import location_managers_3 from "assets/home_location_managers/image@3x.jpg";
import register_location_1_1 from "assets/home_register_location_1/image.jpg";
import register_location_1_2 from "assets/home_register_location_1/image@2x.jpg";
import register_location_1_3 from "assets/home_register_location_1/image@3x.jpg";
import register_location_2_1 from "assets/home_register_location_2/image.jpg";
import register_location_2_2 from "assets/home_register_location_2/image@2x.jpg";
import register_location_2_3 from "assets/home_register_location_2/image@3x.jpg";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  hero: {
    height: "100vh",
    width: "100vw",
    position: "relative",
    display: "block",
    overflow: "hidden !important",
    zIndex: 0,
  },
  full: {},

  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    boxSizing: "border-box",
  },

  title: {
    position: "absolute",
    top: "25vh",
    left: "50px",
    right: "10px",
    "& h1": {
      fontSize: "44px",
      letterSpacing: "-0.46px",
      color: palette.common.white,
    },
    "& h3": {
      marginTop: "24px",
      color: palette.common.white,
    },
    "& h6": {
      color: palette.common.white,
      fontSize: "18px",
    },
    [breakpoints.up("md")]: {
      top: "30vh",
      left: "80px",
      "& h1": {
        fontSize: "64px",
        lineHeight: "72px",
        letterSpacing: "-1.2px",
      },
      "& h3": {
        fontSize: "20px",
      },
    },
  },

  strap: {
    color: palette.common.white,
    fontSize: "17px",
    [breakpoints.up("md")]: {
      fontSize: "20px",
    },
    margin: "20px 0 40px 0",
  },

  iconUnmute: {
    color: palette.common.white,
    marginRight: "5px",
    marginTop: "-7px",
    "&::before": {
      border: `1px solid ${palette.common.white}`,
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      paddingBottom: "80%",
      content: '""',
      position: "absolute",
    },
    "& svg": {
      fill: palette.common.white,
      width: "20px",
      height: "20px",
      zIndex: 2,
    },
  },

  links: {
    position: "relative",
    marginTop: "-150px",
    marginRight: "7vw",
    display: "flex",
    flexDirection: "column",
    backgroundColor: palette.secondary.light,
    padding: "40px 50px",
    zIndex: 1,
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingLeft: "80px",
      marginTop: "-180px",
      paddingBottom: "80px",
    },
  },

  icon: {
    color: palette.common.white,
    marginLeft: "5px",
    marginTop: "-5px",
    height: "40px",
    "&::before": {
      borderRadius: "50%",
      width: "30px",
      height: "30px",
      paddingBottom: "60%",
      backgroundColor: palette.primary.main,
      content: '""',
      position: "absolute",
    },
    "& svg": {
      fill: palette.common.white,
      width: "15px",
      height: "15px",
      zIndex: 2,
    },
  },

  link: {
    fontSize: "17px",
    marginBottom: "20px",
    [breakpoints.up("md")]: {
      fontSize: "17px",
      marginRight: "2vw",
    },
    [breakpoints.up("lg")]: {
      marginRight: "9vw",
    },
    "& h4": {
      display: "none",
      [breakpoints.up("md")]: {
        color: palette.secondary.main,
        display: "block",
      },
    },
  },

  whatwedo: {
    backgroundColor: palette.secondary.light,
    padding: "130px 50px 260px 50px",
    marginTop: "-90px",
    [breakpoints.up("md")]: {
      padding: "130px 50px 268px 134px",
      marginTop: 0,
    },
  },

  ourlocations: {
    backgroundColor: palette.primary.main,
    padding: "0 30px 146px 30px",
    alignItems: "start",
    "& h2": {
      color: palette.common.white,
    },
    "& h3": {
      color: palette.common.white,
    },
    "& p": {
      color: "#99a4a0",
    },
    "& img": {
      width: "calc(100vw - 30px)",
      margin: "-183px 0 60px -50px",
    },
    [breakpoints.up("md")]: {
      display: "flex",
      padding: "132px 80px 30px 80px",
      "& img": {
        width: "36vw",
        margin: "-280px 14vw 280px -80px",
      },
    },
  },

  button: {
    padding: "20px 30px",
    borderRadius: "100vh",
    marginTop: "64px",
    letterSpacing: "2.4px",
    color: palette.primary.main,
    backgroundColor: palette.common.white,
    "&.reverse": {
      color: palette.common.white,
      backgroundColor: palette.primary.main,
    },
    "& svg": {
      width: "15px",
      marginLeft: "15px",
    },
  },

  locationmanagers: {
    paddingBottom: "270px",
    [breakpoints.up("md")]: {
      paddingBottom: 0,
    },
  },

  productionservices: {
    backgroundColor: palette.secondary.light,
    padding: "0 30px 100px 30px",
    alignItems: "start",
    "& img": {
      width: "calc(100vw - 30px)",
      margin: "-195px 0 65px -50px",
    },
    [breakpoints.up("md")]: {
      display: "flex",
      padding: "130px 50px 80px 80px",
      "& img": {
        width: "36vw",
        margin: "-230px 14vw 80px -80px",
      },
    },
  },

  goal: {
    backgroundColor: palette.primary.main,
    padding: "100px 30px",
    marginTop: "100px",
    textAlign: "center",
    "& h2": {
      color: palette.common.white,
    },
    "& h3": {
      color: palette.common.white,
    },
    "& p": {
      color: "#99a4a0",
    },
    [breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      padding: "80px 0",
      alignItems: "start",
      "& img": {
        width: "19vw",
        margin: "-120px 10vw 0 0",
      },
      "& img:last-child": {
        margin: "80px 0 0 10vw",
      },
    },
  },
}));

const Hero = () => {
  const classes = useStyles();
  const [full, setFull] = useState(false);

  return (
    <Box className={classes.hero} onClick={() => full && setFull(false)}>
      <video
        poster={hero_3}
        autoPlay
        muted={true}
        loop
        playsInline
        className={classes.video}
      >
        {/*<source
          src="https://player.vimeo.com/external/506033724.sd.mp4?s=34b4f3b45f7234be1a0c9514f2dd2270f0b90a80&profile_id=164"
          type="video/mp4"
          media="all and (max-width: 960px)"
        />*/}
        <source
          src="https://player.vimeo.com/external/506033724.hd.mp4?s=47bb2b91429d98ae1fac0f0b0d036ecba403c170&profile_id=175"
          type="video/mp4"
        />
      </video>
      <Box className={classes.title}>
        <Typography variant="h1">
          The essential
          <br />
          location company.
        </Typography>
        <Typography className={classes.strap}>
          Over 20 years experience scouting and facilitating location shoots.
        </Typography>
        {/*<Box display="flex">
          <IconButton edge="end" className={classes.iconUnmute} aria-label="unmute" onClick={() => setFull(true)}>
            <UnmuteIcon />
          </IconButton>
          <Typography variant="subtitle2">{full ? 'Mute' : 'Unmute'}</Typography>
      </Box>*/}
      </Box>
    </Box>
  );
};

const Links = () => {
  const classes = useStyles();

  return (
    <Box className={classes.links}>
      <RouterLink to="/library" className={classes.link}>
        <Box display="flex">
          Find a location
          <IconButton edge="end" className={classes.icon}>
            <ArrowIcon />
          </IconButton>
        </Box>
        <Typography variant="h4">Search our extensive library</Typography>
      </RouterLink>
      <RouterLink to="/scouting" className={classes.link}>
        <Box display="flex">
          Book a Location Manager
          <IconButton edge="end" className={classes.icon}>
            <ArrowIcon />
          </IconButton>
        </Box>
        <Typography variant="h4">Find the perfect team for you</Typography>
      </RouterLink>
      <RouterLink to="/production" className={classes.link}>
        <Box display="flex">
          Get a production quote
          <IconButton edge="end" className={classes.icon}>
            <ArrowIcon />
          </IconButton>
        </Box>
        <Typography variant="h4">
          Stills &amp; film production services
        </Typography>
      </RouterLink>
    </Box>
  );
};

const WhatWeDo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.whatwedo}>
      <About title="What we do" copy="home_what_we_do" spread />
    </Box>
  );
};

const OurLocations = () => {
  const classes = useStyles();

  return (
    <Box className={classes.ourlocations}>
      <img
        src={what_we_do_1}
        srcSet={`${what_we_do_1} 384w, ${what_we_do_2} 768w, ${what_we_do_3} 1152w`}
        alt="Our library"
      />
      <About
        title="Our library"
        copy="home_our_locations"
        button={
          <Button
            variant="contained"
            component={RouterLink}
            to={`/library`}
            className={classes.button}
          >
            Search library
            <ArrowIcon />
          </Button>
        }
      />
    </Box>
  );
};

const LocationManagers = () => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box className={classes.locationmanagers}>
      {!desktop && <TeamCarousel />}
      <Box
        display="flex"
        justifyContent="space-between"
        padding={desktop ? "200px 80px" : "30px"}
      >
        <About
          title="Location managers"
          copy="home_location_managers"
          button={
            <Button
              variant="contained"
              component={RouterLink}
              to={`/scouting`}
              className={`${classes.button} reverse`}
            >
              Meet the team
              <ArrowIcon />
            </Button>
          }
        />
        {desktop && <TeamGallery />}
      </Box>
    </Box>
  );
};

const ProductionServices = () => {
  const classes = useStyles();

  return (
    <Box className={classes.productionservices}>
      <img
        src={location_managers_1}
        srcSet={`${location_managers_1} 384w, ${location_managers_2} 768w, ${location_managers_3} 1152w`}
        alt="Production services"
      />
      <About
        title="Production services"
        copy="home_production_services"
        button={
          <Button
            variant="contained"
            component={RouterLink}
            to={`/production`}
            className={`${classes.button} reverse`}
          >
            Find out more
            <ArrowIcon />
          </Button>
        }
      />
    </Box>
  );
};

const RegisterLocation = () => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box className={classes.goal}>
      {desktop && (
        <img
          src={register_location_1_1}
          srcSet={`${register_location_1_1} 384w, ${register_location_1_2} 768w, ${register_location_1_3} 1152w`}
          alt="Production services"
        />
      )}
      <About
        title="Register a location"
        copy="home_register_location"
        button={
          <Button
            variant="contained"
            component={RouterLink}
            to="/contact"
            className={classes.button}
          >
            Submit a property
            <ArrowIcon />
          </Button>
        }
      />
      {desktop && (
        <img
          src={register_location_2_1}
          srcSet={`${register_location_2_1} 384w, ${register_location_2_2} 768w, ${register_location_2_3} 1152w`}
          alt="Production services"
        />
      )}
    </Box>
  );
};

const Home = () => (
  <Template type="home" page="home">
    <>
      <Hero />
      <Links />
      <WhatWeDo />
      <OurLocations />
      <LocationManagers />
      <ProductionServices />
      <InstagramCarousel limit={3} />
      <RegisterLocation />
    </>
  </Template>
);

export default Home;
