import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParameterizedQuery } from "react-fetching-library";
import Skeleton from "react-loading-skeleton";
import {
  Grid,
  IconButton,
  makeStyles,
  Box,
  Typography,
  Chip,
} from "@material-ui/core";
import {
  AddCircle as AddIcon,
  PhotoCameraOutlined as CameraIcon,
  Cancel as CancelIcon,
  Visibility as ViewIcon,
} from "@material-ui/icons";

import { ILocation, ILocationImage } from "resources/interface";
import { useLocationContext } from "resources/hooks";
import { ActionType } from "resources/constants";
import { fetchLocationImages } from "resources/api";

import Dropbox from "images/Dropbox";

//import { ReactComponent as AddIcon } from 'assets/icons/plus-icon.svg';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  box: {
    padding: "30px",
    [breakpoints.up("md")]: {
      padding: "40px 80px",
    },
  },

  image: {
    width: "100%",
    [breakpoints.up("md")]: {
      height: "19.2vw",
      objectFit: "cover",
    },
  },

  button: {
    width: "fit-content",
    padding: 0,
    borderRadius: 0,
    marginRight: "20px",
  },

  icon: {
    width: "20px",
    height: "20px",
    marginRight: "5px",
    color: palette.primary.main,
  },

  basketCount: {},
}));

interface IProps2 {
  location: ILocation;
  onComplete: any;
}
const AddLocation = ({ location, onComplete }: IProps2) => {
  const { payload: payloadLocationImages, query } =
    useParameterizedQuery(fetchLocationImages);
  const { id } = location;

  useEffect(() => {
    if (id) {
      query(id);
    }
  }, [id]);

  useEffect(() => {
    if (payloadLocationImages) onComplete(payloadLocationImages);
  }, [payloadLocationImages, onComplete]);

  return null;
};

interface IProps {
  location?: ILocation;
  count?: number;
}
const LocationRow = ({ location, count }: IProps) => {
  const classes = useStyles();
  const { dispatch } = useLocationContext();
  const [addLocation, setAddLocation] = useState(false);
  //  const [showImages, setShowImages] = useState(false);

  if (!location)
    return (
      <Box display="flex" flexDirection="column" className={classes.box}>
        <Grid container spacing={2} alignItems="stretch">
          {[0, 1, 2].map((index) => (
            <Grid item xs={12} md={4} key={index}>
              <Skeleton className={classes.image} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );

  const { id, fs_area_reference, fs_postcode, images } = location;
  const basketPage = window.location.pathname === "/basket";

  const onComplete = (locationImages: Array<ILocationImage>) => {
    setAddLocation(false);

    dispatch({
      type: ActionType.addToBasket,
      selectedLocation: Object.assign({}, location, { images: locationImages }),
    });
  };

  /*  const ActionButtons = () => (
    <>
      {basketPage && (
        <Tooltip title="Back to results" arrow className={classes.back}>
          <IconButton aria-label="back to results" component={RouterLink} to="/library">
            <BackIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}

      {basketPage && (
        <Tooltip title="Show all" arrow>
          <IconButton aria-label="show all" onClick={() => setShowImages(!showImages)}>
            <CameraIcon className={classes.icon} />
            {images && <div className={classes.basketCount}>{images.length}</div>}
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="View location" arrow>
        <IconButton aria-label="view location" component={RouterLink} to={locationURL}>
          <ViewIcon className={classes.icon} />
        </IconButton>
      </Tooltip>

      {!basketPage && (
        <Tooltip title="Add all location images to basket" arrow>
          <IconButton aria-label="add to basket" onClick={() => setAddLocation(true)}>
            <AddIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}

      {basketPage && (
        <Tooltip title="Remove all location images from basket" arrow>
          <IconButton
            aria-label="remove basket"
            onClick={() =>
              dispatch({
                type: ActionType.removeFromBasket,
                selectedLocationId: id,
              })
            }
          >
            <RemoveAllIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
    </>
  ); */

  const removeLocation = () => {
    dispatch({
      type: ActionType.removeFromBasket,
      selectedLocationId: location.id,
    });
  };

  let link = `location/${id}`;
  if (fs_area_reference)
    link += `/${encodeURIComponent(
      fs_area_reference.trim().replaceAll(" ", "-")
    )}`;

  return (
    <>
      {addLocation && (
        <AddLocation location={location} onComplete={onComplete} />
      )}
      <Box className={classes.box}>
        {images && images.length && (
          <>
            <RouterLink to={link}>
              <Grid container spacing={2} alignItems="stretch">
                {images.map(
                  ({ ext_itemps_id, ext_itemps_product_set_image }) => (
                    <Grid item xs={12} md={4} key={ext_itemps_id}>
                      <img
                        src={ext_itemps_product_set_image}
                        alt="View location"
                        className={classes.image}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </RouterLink>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="caption">#{id}</Typography>
                <Typography variant="subtitle2">
                  {fs_area_reference},{" "}
                  {fs_postcode && fs_postcode.split(" ")[0]}
                </Typography>
                {!basketPage && (
                  <Box display="flex" flexWrap="wrap">
                    <IconButton
                      aria-label="view entire location"
                      component={RouterLink}
                      to={link}
                      className={classes.button}
                    >
                      <ViewIcon className={classes.icon} />
                      <Typography color="primary">
                        View entire location
                      </Typography>
                    </IconButton>
                    <IconButton
                      aria-label="add entire location to basket"
                      onClick={() => setAddLocation(true)}
                      className={classes.button}
                    >
                      <AddIcon className={classes.icon} />
                      <Typography color="primary">
                        Add entire location to basket
                      </Typography>
                    </IconButton>
                  </Box>
                )}
                {count && (
                  <IconButton
                    aria-label="view entire location"
                    component={RouterLink}
                    to={link}
                    className={classes.button}
                  >
                    <Typography variant="h4">
                      <CameraIcon
                        className={classes.icon}
                        style={{ verticalAlign: "text-bottom" }}
                      />
                      {count} images from this location
                    </Typography>
                  </IconButton>
                )}
              </Box>
              {basketPage && (
                <Box display="flex">
                  <Chip
                    color="primary"
                    label="Remove"
                    deleteIcon={<CancelIcon />}
                    onDelete={removeLocation}
                    onClick={removeLocation}
                  />
                  <Dropbox images={images} locId={id} basket />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
  /*      <Card variant="outlined" className={classes.row}>
        <CardHeader
          title={`${fs_area_reference}, ${fs_postcode.split(' ')[0]}`}
          subheader={
            <RouterLink to={locationURL} title="View location">
              #{id}
            </RouterLink>
          }
          action={<ActionButtons />}
          classes={{ root: classes.header, content: classes.headerContent }}
        />
        <CardContent>
          <Collapse in={showImages} collapsedHeight={260}>
            <Grid container spacing={2} alignItems="stretch">
              {images &&
                images.map(({ ext_itemps_id, ext_itemps_product_set_image }) => (
                  <Grid item xs={12} sm={4} key={ext_itemps_id}>
                    <Card variant="outlined" className={classes.card}>
                      {basketPage && (
                        <CardHeader
                          action={
                            <Tooltip title="Remove from basket" arrow>
                              <IconButton
                                aria-label="remove from basket"
                                onClick={() =>
                                  dispatch({
                                    type: ActionType.removeFromBasket,
                                    selectedImageId: ext_itemps_id,
                                  })
                                }
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        />
                      )}
                      <CardMedia
                        image={ext_itemps_product_set_image}
                        title="View location"
                        className={classes.media}
                        component={RouterLink}
                        to={locationURL}
                      />
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Collapse>
        </CardContent>
      </Card> */
};

export default LocationRow;
