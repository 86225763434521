import React, { useState } from "react";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";

import Map from "tags/Map";
import { ReactComponent as MapIcon } from "assets/icons/map.svg";

const useStyles = makeStyles(({ palette }) => ({
  box: { position: "relative", width: "15%", minWidth: "160px" },

  button: {
    width: "100%",
    height: "54px",
    border: "none",
    borderRadius: "100vh",
    backgroundColor: "#99A4A0",
    outline: "none",
    "& svg": {
      verticalAlign: "middle",
      margin: "-5px 10px 0 10px",
      width: "30px",
      height: "30px",
    },
    "&:hover": {
      backgroundColor: "#193027",
    },
  },

  label: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontSize: "14px",
    marginLeft: "15px",
  },

  dropdown: {
    position: "absolute",
    width: "130px",
    top: "35px",
    left: "15px",
    backgroundColor: palette.secondary.light,
    listStyle: "none",
    padding: 0,
    zIndex: 1,
    "& li": {
      padding: "5px 10px",
      cursor: "pointer",
    },
  },
}));

const MapSearch = () => {
  const classes = useStyles();

  const [showMenu, setShowMenu] = useState(false);
  const [showMap, setShowMap] = useState("");

  const handleMap = (type: string) => {
    setShowMenu(false);
    setShowMap(type);
  };

  return (
    <Box className={classes.box}>
      <IconButton
        className={classes.button}
        onClick={() => setShowMenu(!showMenu)}
      >
        <Typography className={classes.label}>Map Search</Typography>
        <MapIcon />
      </IconButton>
      {showMenu && (
        <ul className={classes.dropdown}>
          <li onClick={() => handleMap("London")}>
            <Typography>London Map</Typography>
          </li>
          <li onClick={() => handleMap("UK")}>
            <Typography>UK Map</Typography>
          </li>
        </ul>
      )}
      {!!showMap.length && <Map type={showMap} action={() => setShowMap("")} />}
    </Box>
  );
};

export default MapSearch;
