import React, { useState } from "react";
import { useQuery } from "react-fetching-library";
import { FormControl, makeStyles } from "@material-ui/core";

import { fetchWhereTags } from "resources/api";
import { useLocationContext } from "resources/hooks";
import { ActionType } from "resources/constants";
import { ITag } from "resources/interface";
import { postcodes } from "resources/postcodes";

import MultiChipSelect from "structure/MultiChipSelect";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  input: {
    zIndex: 5,
    width: "20%",
    minWidth: "180px",
    height: "54px",
    borderRadius: "100vh",
    backgroundColor: "#e6e9e8",
    padding: "12px 0 12px 20px",
    [breakpoints.up("md")]: {
      paddingTop: 0,
      "& input": {
        marginTop: "-5px",
      },
      "& label": {
        marginTop: "-5px",
        color: "#000",
        fontSize: "14px",
      },
    },
  },
  input2: {
    width: "100%",
  },

  buttons: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "block",
    },
  },

  expand: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "block",
      position: "absolute",
      right: "5px",
      top: "55px",
    },
  },
}));

type Props = {
  handleClick?: () => void;
};
const WhereTags = ({ handleClick }: Props) => {
  const classes = useStyles();
  const { locationContext, dispatch } = useLocationContext();

  const { tagList } = locationContext;
  const { payload: payloadTags } = useQuery(fetchWhereTags);
  const [userInput, setUserInput] = useState("");

  const handleSelect = (selectedItem: ITag) => {
    setUserInput("");
    if (tagList.includes(selectedItem)) {
      removeSelectedItem(selectedItem);
    } else {
      addSelectedItem(selectedItem);
    }
    handleClick && handleClick();
  };

  const handleEnter = () => {
    addSelectedItem({
      id: "%area",
      filter_item_title: userInput,
    });
    setUserInput("");
    handleClick && handleClick();
  };

  const addSelectedItem = (tag: ITag) => {
    dispatch({
      type: ActionType.addFilterTag,
      selectedTag: tag,
      allTags: payloadTags,
    });
  };

  const removeSelectedItem = (tag: ITag) => {
    dispatch({
      type: ActionType.removeFilterTag,
      selectedTag: tag,
    });
  };

  const handleChangeInput = (inputVal: string) => {
    if (inputVal && ![",", "[", "]"].some((char) => inputVal.includes(char))) {
      setUserInput(inputVal);
    } else setUserInput("");

    //    const t = inputVal.split(",");
    //    if (JSON.stringify(t) !== JSON.stringify(tagList)) {
    //      setUserInput(inputVal);
    //    }
  };

  if (!payloadTags) return null;

  return (
    <>
      <FormControl
        variant={handleClick ? "standard" : "outlined"}
        className={handleClick ? classes.input2 : classes.input}
      >
        <MultiChipSelect
          onInputValueChange={handleChangeInput}
          inputValue={userInput}
          inputLabel={
            handleClick ? "Enter an area/postcode" : "Area / Postcode"
          }
          inputStyle={handleClick ? {} : { disableUnderline: true }}
          availableItems={[
            ...payloadTags.filter(
              ({ filter_item_title }) => filter_item_title === "South East"
            ),
            { id: "LondonAll", filter_item_title: "London All" },
            ...payloadTags.filter(
              ({ filter_item_title }) => filter_item_title !== "South East"
            ),
            ...postcodes,
          ]}
          show={payloadTags.length}
          selectedItem={tagList}
          onChange={handleSelect}
          onEnter={handleEnter}
          onRemoveItem={removeSelectedItem}
          itemToString={(item: ITag) => item.filter_item_title}
        />
      </FormControl>
    </>
  );
};

export default WhereTags;
