import React from "react";
import Downshift from "downshift";
import { TextField, MenuItem, Paper, withStyles } from "@material-ui/core";
import { ExpandMore as ExpandIcon } from "@material-ui/icons";

const styles = ({ breakpoints }) => ({
  chipContainer: {
    backgroundColor: "transparent",
    display: "inline-block",
    marginBottom: 10,
  },
  chip: {
    marginTop: 10,
    marginRight: 5,
  },
  paper: {
    width: "290px",
    maxHeight: "60vh",
    overflowY: "auto",
    marginTop: "10px",
  },
  expand: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "block",
      position: "absolute",
      right: "15px",
      top: "8px",
      color: "#99A4A0",
      fontSize: "36px",
      cursor: "pointer",
    },
  },
});

const renderInput = (inputProps) => {
  const { InputProps, availableItems, inputLabel } = inputProps;

  const allItemSelected = availableItems.length === 0;
  return (
    <TextField
      fullWidth
      label={inputLabel}
      disabled={allItemSelected}
      InputProps={InputProps}
    />
  );
};

/* const renderChipList = inputProps => {
  const { classes, selectedItem, onRemoveItem } = inputProps;

  return (
    <div className={classes.chipContainer}>
      {selectedItem.length > 0 &&
        selectedItem.map(item => (
          <Chip
            key={item.id}
            className={classes.chip}
            label={item.filter_item_title}
            deleteIcon={<CancelIcon />}
            onDelete={() => onRemoveItem(item)}
            onClick={() => onRemoveItem(item)}
          />
        ))}
    </div>
  );
}; */

const renderSuggestion = (params) => {
  const { item, index, itemProps, highlightedIndex, selectedItem } = params;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem.indexOf(item.filter_item_title) > -1;

  return (
    !isSelected && (
      <MenuItem
        {...itemProps}
        key={`${item.id}${index}`}
        selected={isHighlighted}
        component="div"
        style={{ fontSize: "14px" }}
      >
        {item.filter_item_title}
      </MenuItem>
    )
  );
};

function* filter(array, condition, maxSize) {
  if (!maxSize || maxSize > array.length) {
    maxSize = array.length;
  }
  let count = 0;
  let i = 0;
  while (count < maxSize && i < array.length) {
    if (condition(array[i])) {
      yield array[i];
      count++;
    }
    i++;
  }
}

const getSuggestions = (inputValue, itemList, show) => [
  ...filter(
    itemList,
    (item) =>
      item.filter_item_title.toLowerCase().includes(inputValue.toLowerCase()),
    show
  ),
];

function MultiChipSelect(props) {
  const {
    classes,
    availableItems,
    show,
    inputStyle,
    inputLabel,
    onRemoveItem,
    onEnter,
    ...rest
  } = props;

  const handleKeypress = (e, val, toggleMenu) => {
    if (
      e.charCode === 13 &&
      !getSuggestions(val, availableItems, show).length &&
      onEnter
    ) {
      toggleMenu();
      onEnter();
    }
  };

  return (
    <Downshift {...rest}>
      {({
        getInputProps,
        getItemProps,
        inputValue,
        selectedItem,
        highlightedIndex,
        toggleMenu,
        isOpen,
      }) => (
        <div>
          {/*renderChipList({
            classes,
            onRemoveItem,
            selectedItem
          })*/}

          {renderInput({
            classes,
            selectedItem,
            availableItems,
            inputLabel,
            InputProps: {
              ...inputStyle,
              ...getInputProps({
                onClick: () => toggleMenu(),
              }),
              onKeyPress: (e) => handleKeypress(e, inputValue, toggleMenu),
            },
          })}

          {isOpen && (
            <Paper className={classes.paper} square>
              {getSuggestions(inputValue, availableItems, show).map(
                (item, index) =>
                  renderSuggestion({
                    item,
                    index,
                    itemProps: getItemProps({
                      item: item,
                    }),
                    highlightedIndex,
                    selectedItem,
                  })
              )}
            </Paper>
          )}
          <ExpandIcon className={classes.expand} onClick={() => toggleMenu()} />
        </div>
      )}
    </Downshift>
  );
}

export default withStyles(styles)(MultiChipSelect);
