import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';

import { debugConsole } from 'resources/utils';

import ErrorPage from 'pages/ErrorPage';

class ErrorBoundary extends Component {
  public state = { hasError: false, errorMsg: null };

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMsg: error.message };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    debugConsole('boundary error', error.message);
    debugConsole('boundary error info', info);
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtra('stack', info);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError)
      return this.state.errorMsg === 'Image search unavailable' ? (
        <ErrorPage text="image" />
      ) : (
        <ErrorPage text="internal" />
      );
    return this.props.children;
  }
}

export default ErrorBoundary;
