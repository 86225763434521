import { Action } from "react-fetching-library";

import {
  IContent,
  ITag,
  IWork,
  ILocation,
  ILocationImage,
  ITeam,
  IContact,
  ILogo,
  IInsta,
  IEmailTo,
} from "./interface";

export const fetchContent: Action<IContent[]> = {
  method: "GET",
  endpoint: "/dynamic-block-copy.html",
};

export const fetchWhatTags: Action<ITag[]> = {
  method: "GET",
  endpoint: "/what-tags.html",
};

export const fetchWhereTags: Action<ITag[]> = {
  method: "GET",
  endpoint: "/where-tags.html",
};

export const fetchHighlightTags: Action<ITag[]> = {
  method: "GET",
  endpoint: "/highlight-tags.html",
};

export const fetchCategoryTags: Action<ITag[]> = {
  method: "GET",
  endpoint: "/category-tags.html",
};

export const fetchWork: Action<IWork[]> = {
  method: "GET",
  endpoint: "/work.html",
};

export const fetchTeam: Action<ITeam[]> = {
  method: "GET",
  endpoint: "/team.html",
};

export const fetchLogos: Action<ILogo[]> = {
  method: "GET",
  endpoint: "/logos.html",
};

export const fetchInsta: Action<IInsta> = {
  method: "GET",
  endpoint: `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url&&access_token=${process.env.REACT_APP_INSTA_TOKEN}`,
};

type TFetchFGICounts = (tagList: Array<ITag>) => Action;
export const fetchFGICounts: TFetchFGICounts = (tagList) => {
  return {
    method: "GET",
    endpoint:
      "/request.ashx?action=search-filter&sectionCounts=1&tagIdCsv=" +
      tagList.map(({ id }) => id).join(","),
  };
};

type TFetchLocations = (csvs: {
  tagIdCSV: string;
  postcodeCSV: string;
  areaCSV: string;
  pageNumber: number;
  pageSize: number;
  imageTag?: ITag;
}) => Action<ILocation[]>;
export const fetchLocations: TFetchLocations = ({
  tagIdCSV,
  postcodeCSV,
  imageTag,
  pageNumber,
  pageSize,
  areaCSV,
}) => {
  const FormData = require("form-data");
  const form = new FormData();
  form.append("file", imageTag?.data);

  return imageTag && imageTag.data
    ? {
        method: "POST",
        endpoint: `/request.ashx?action=search-filter${
          !!tagIdCSV.length ? `&tagIdCsv=${tagIdCSV}` : ""
        }${!!postcodeCSV.length ? `&postcodes=${postcodeCSV}` : ""}${
          !!areaCSV.length ? `&areas=${areaCSV}` : ""
        }&searchImageHash=${encodeURIComponent(
          imageTag.filter_item_title
        )}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        body: form,
      }
    : {
        method: "GET",
        endpoint: `/request.ashx?action=search-filter${
          !!tagIdCSV.length ? `&tagIdCsv=${tagIdCSV}` : ""
        }${!!postcodeCSV.length ? `&postcodes=${postcodeCSV}` : ""}${
          !!areaCSV.length ? `&areas=${areaCSV}` : ""
        }${
          !!imageTag
            ? `&searchImageHash=${encodeURIComponent(
                imageTag.filter_item_title
              )}`
            : ""
        }&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      };
};

type TFetchLocation = (id: string) => Action<ILocation[]>;
export const fetchLocation: TFetchLocation = (id) => ({
  method: "GET",
  endpoint: `/location.html?cr_i=${id}`,
});

type TFetchLocationImages = (id: string) => Action<ILocationImage[]>;
export const fetchLocationImages: TFetchLocationImages = (id) => ({
  method: "GET",
  endpoint: `/location-images.html?cr_i=${id}`,
});

type TPostContact = (formValues: IContact) => Action;
export const postContact: TPostContact = (formValues) => ({
  method: "POST",
  endpoint: `/request.ashx?action=form-response&formName=SaltWebContact&sourceUrl=contact`,
  body: formValues,
});

type TPostEmailTo = (formValues: IEmailTo) => Action;
export const postEmailTo: TPostEmailTo = (formValues) => ({
  method: "POST",
  endpoint: `/request.ashx?action=form-response&formName=ToOther_SiteLinkSuggestion&sourceUrl=location`,
  body: formValues,
});

type TPostRegister = (formValues: IContact) => Action;
export const postRegister: TPostRegister = (formValues) => ({
  method: "POST",
  endpoint: `/request.ashx?action=form-response&formName=SaltWebProperty&sourceUrl=register`,
  body: formValues,
});

/*
type TPostDownload = (formValues: Array<IDownload>) => Action;
export const postDownload: TPostDownload = (formValues) => ({
  method: 'POST',
  endpoint: '/MzCms_Overrides/TrackedDownload.ashx',
  body: {
    action: 'gallery-images',
    payload: formValues,
  },
});
*/
