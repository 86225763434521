import React, { useEffect } from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ArrowForward as ArrowIcon } from "@material-ui/icons";

import { fetchLocations } from "resources/api";
import { useLocationContext } from "resources/hooks";
import { ActionType } from "resources/constants";
import { ITag } from "resources/interface";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  box: {
    cursor: "pointer",
    overflow: "hidden",
  },
  greenBox: {
    position: "absolute",
    bottom: 0,
    height: "40px",
    backgroundColor: palette.primary.main,
    width: "100%",
    opacity: 0.8,
  },
  textBox: {
    position: "absolute",
    bottom: 0,
    padding: "5px 10px",
    color: palette.common.white,
    "& h6": {
      color: palette.common.white,
      fontSize: "16px",
    },
  },
  image: {
    width: "100%",
    marginBottom: "-11px",
    [breakpoints.up("md")]: {
      height: "14vw",
    },
  },
  button: {
    width: "fit-content",
    padding: 0,
    borderRadius: 0,
    marginRight: "20px",
  },
  icon: {
    marginRight: "10px",
    backgroundColor: palette.common.white,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    "& svg": {
      fill: palette.primary.main,
      width: "15px",
      marginTop: "-2px",
    },
  },
}));

const New = ({ id, filter_item_title }: ITag) => {
  const classes = useStyles();
  const { loading, payload, query } = useParameterizedQuery(fetchLocations);
  const { dispatch } = useLocationContext();

  useEffect(() => {
    query({
      tagIdCSV: id,
      postcodeCSV: "",
      areaCSV: "",
      pageNumber: 1,
      pageSize: 50,
      imageTag: undefined,
    });
  }, []);

  if (loading || !payload || !Array.isArray(payload) || !payload.length)
    return null;

  return (
    <Box
      className={classes.box}
      onClick={() => {
        window.scrollTo(0, 0);
        dispatch({
          type: ActionType.addFilterTag,
          selectedTag: { id, filter_item_title },
        });
      }}
    >
      <Box style={{ position: "relative" }}>
        <img
          src={`${process.env.REACT_APP_HOST}${payload[0].loc_image_file1}`}
          alt={`${filter_item_title}s`}
          className={classes.image}
        />
        <Box className={classes.greenBox} />
        <Box className={classes.textBox}>
          <IconButton
            aria-label={`${filter_item_title}s`}
            className={classes.button}
          >
            <Box className={classes.icon}>
              <ArrowIcon />
            </Box>
            <Typography variant="subtitle2">{filter_item_title}s</Typography>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default New;
