import React, { forwardRef, Ref, useMemo, useEffect } from "react";
import { useParameterizedQuery } from "react-fetching-library";
import {
  Button,
  Box,
  Typography,
  Grid,
  makeStyles,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Theme,
  CircularProgress,
} from "@material-ui/core";

import { ITag } from "resources/interface";
import { ActionType } from "resources/constants";
import { useLocationContext } from "resources/hooks";
import { fetchFGICounts } from "resources/api";

import { ReactComponent as FilterIcon } from "assets/icons/filters2.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const filterGroups = [
  "Category",
  "Geographical Area",
  "Type",
  "Architectural Period / Style",
  "Description / Look",
  "Space / Feature",
];

interface IStyleProps {
  selected?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>(({ palette }) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F4F4F4",
    paddingLeft: "10px",
  },

  box: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },

  button: {
    borderRadius: "100vh",
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    textTransform: "none",
    padding: "8px 16px",
  },

  filterGroup: {
    fontSize: "13px",
    color: "#193027",
    padding: "0 8px",
    height: "30px",
    overflow: "hidden",
  },

  fgBox: {
    height: "100%",
    backgroundColor: "#E6E9E8",
    borderLeft: "1px solid white",
  },

  filterGroupItem: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "11px",
    padding: "0 8px",
    height: "28px",
    overflow: "hidden",
    color: ({ selected }) => (selected ? "#fff" : "#193027"),
    backgroundColor: ({ selected }) => (selected ? "#99A4A0" : "transparent"),
    cursor: ({ disabled }) => (disabled ? "auto" : "pointer"),
    "&:hover": {
      backgroundColor: "#99A4A0",
    },
  },
}));

interface IProps2 {
  id: string;
  label: string;
  count: number;
  selected: boolean;
  disabled: boolean;
  london?: Array<ITag>;
}
const FGI = ({ id, label, count, selected, disabled, london }: IProps2) => {
  const classes = useStyles({ selected: selected, disabled: disabled });
  const { dispatch } = useLocationContext();

  const chooseTag = (tag: ITag) => {
    dispatch({
      type: ActionType.addFilterTag,
      selectedTag: { id: "PAUSE", filter_item_title: "PAUSE" },
    });
    if (tag.id === "LondonAll" && london) {
      london.forEach(({ id, filter_item_title }) => {
        dispatch({
          type: selected ? ActionType.removeFilterTag : ActionType.addFilterTag,
          selectedTag: { id: id.toString(), filter_item_title },
        });
      });
    } else {
      dispatch({
        type: selected ? ActionType.removeFilterTag : ActionType.addFilterTag,
        selectedTag: tag,
      });
    }
  };

  return (
    <Typography
      className={classes.filterGroupItem}
      key={id}
      onClick={() =>
        !disabled && chooseTag({ id: id.toString(), filter_item_title: label })
      }
    >
      <span>{label}</span>
      <span>({disabled ? "" : count})</span>
    </Typography>
  );
};

interface IProps {
  action(): void;
}
const FGICounts = forwardRef(({ action }: IProps, ref: Ref<any>) => {
  const classes = useStyles({});
  const { dispatch } = useLocationContext();
  const {
    locationContext: { tagList },
  } = useLocationContext();
  const { payload, loading, query } = useParameterizedQuery(fetchFGICounts);
  const disabled = !payload || loading;

  const originalTagList = useMemo(() => [...tagList], []);

  useEffect(() => {
    query(tagList.filter(({ id }) => id !== "PAUSE"));
  }, [tagList.length]);

  // const refArray = [
  //   ...Array(26)
  //     .fill(null)
  //     .map((_, index) => createRef() as MutableRefObject<HTMLDivElement>),
  // ];

  const removeAll = () => {
    dispatch({
      type: ActionType.removeAllFilters,
    });
  };

  const restore = () => {
    dispatch({
      type: ActionType.removeAllFilters,
    });
    dispatch({
      type: ActionType.addFilterTags,
      selectedTags: originalTagList,
    });
    action();
  };

  const execute = () => {
    dispatch({
      type: ActionType.removeAllFilters,
    });
    dispatch({
      type: ActionType.addFilterTags,
      selectedTags: tagList.filter(({ id }) => id !== "PAUSE"),
    });
    action();
  };

  return (
    <Dialog
      open={true}
      onClose={action}
      maxWidth={false}
      fullWidth
      PaperProps={{ style: { height: "100%" } }}
      ref={ref}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Box className={classes.box}>
          <FilterIcon />
          <Typography variant="h3" style={{ margin: "0" }}>
            Search Filters
          </Typography>
        </Box>
        <Box className={classes.box}>
          {disabled && (
            <CircularProgress
              thickness={2}
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          )}
          <Button
            variant="contained"
            onClick={execute}
            className={classes.button}
            disabled={disabled}
          >
            Run Search
          </Button>
          <Button
            variant="contained"
            onClick={removeAll}
            className={classes.button}
            disabled={disabled || !tagList.length}
          >
            Clear Search
          </Button>
          <IconButton
            aria-label="close"
            onClick={restore}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <>
          <Grid container>
            {filterGroups.map((fg, index) => {
              let fgi: Array<ITag> = [];
              let london: Array<ITag> = [];

              if (payload && payload[fg]) {
                fgi = payload[fg].sort((a: ITag, b: ITag) =>
                  a.filter_item_title > b.filter_item_title ? 1 : -1
                );

                if (fg === "Geographical Area") {
                  london = fgi.filter(({ filter_item_title }) =>
                    filter_item_title.startsWith("London")
                  );
                  fgi = [
                    ...fgi.filter(
                      ({ filter_item_title }) =>
                        filter_item_title === "South East"
                    ),
                    { id: "LondonAll", filter_item_title: "London All" },
                    ...london,
                    ...fgi.filter(
                      ({ filter_item_title }) =>
                        !filter_item_title.startsWith("London") &&
                        filter_item_title !== "South East"
                    ),
                  ];
                }
              }

              return (
                <Grid item xs={2} key={fg}>
                  <Typography className={classes.filterGroup}>{fg}</Typography>
                  <Box className={classes.fgBox}>
                    {payload ? (
                      fgi.map(
                        ({ id, filter_item_title, in_selection_count }) =>
                          (index < 2 ||
                            (!!in_selection_count &&
                              in_selection_count > 0)) && (
                            <FGI
                              id={id}
                              label={filter_item_title}
                              count={
                                id === "LondonAll"
                                  ? london.reduce(
                                      (acc, { in_selection_count }) =>
                                        acc + (in_selection_count || 0),
                                      0
                                    )
                                  : in_selection_count || 0
                              }
                              selected={
                                id === "LondonAll"
                                  ? london.every(({ id: id1 }) =>
                                      tagList.find(
                                        ({ id: id2 }) =>
                                          Number(id1) === Number(id2)
                                      )
                                    )
                                  : tagList.some(
                                      ({ id: fgiId }) =>
                                        Number(id) === Number(fgiId)
                                    )
                              }
                              disabled={disabled}
                              london={london.length ? london : undefined}
                              key={id}
                            />
                          )
                      )
                    ) : (
                      <FGI
                        id=""
                        label="Loading..."
                        count={0}
                        disabled
                        selected={false}
                      />
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </>
      </DialogContent>
    </Dialog>
  );
});

export default FGICounts;
