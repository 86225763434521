import React from "react";
import { useQuery } from "react-fetching-library";
import { Grid, makeStyles, Box, Typography } from "@material-ui/core";

import { fetchWork } from "resources/api";
import { IWork } from "resources/interface";

import Template from "structure/Template";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  box: {
    padding: "30px",
    [breakpoints.up("md")]: {
      padding: "80px",
      paddingTop: "30px",
    },
  },

  details: {
    padding: "100px 0",
  },

  details2: {
    padding: "30px",
    marginTop: "-70px",
    "& h1": {
      color: palette.primary.main,
      [breakpoints.up("md")]: {
        fontSize: "36px",
        marginTop: "80px",
      },
    },
    "& h3": {
      fontSize: "24px",
      color: "#707070",
      [breakpoints.up("md")]: {
        fontSize: "36px",
      },
    },
    "& div": {
      color: "#707070",
      marginBottom: "10px",
    },
    [breakpoints.up("md")]: {
      padding: "50px 130px",
    },
  },

  grid: {
    backgroundColor: palette.secondary.light,
    marginTop: "calc(-120px - 100vw * 9 / 32)",
    padding: "30px",
    paddingTop: "calc(100vw * 9 / 32)",
    "& p": {
      fontSize: "14px",
      [breakpoints.up("md")]: {
        fontSize: "17px",
      },
    },
    [breakpoints.up("md")]: {
      padding: "80px 130px",
      paddingTop: "calc(100vw * 9 / 32)",
    },
  },
}));

const Details = ({
  project_name,
  client_name,
  project_date,
  director_name,
  production_company,
  more_info_text,
}: IWork) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.details}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={6}>
            <Typography>
              <b>Project</b>
            </Typography>
            <Typography>{project_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <b>Date</b>
            </Typography>
            <Typography>{project_date}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <b>Client</b>
            </Typography>
            <Typography>{client_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <b>Director</b>
            </Typography>
            <Typography>{director_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <b>Production company</b>
            </Typography>
            <Typography>{production_company}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.details2}>
        <Typography variant="h1">{project_name}</Typography>
        <Typography variant="h3">{production_company}</Typography>
        <div dangerouslySetInnerHTML={{ __html: more_info_text }} />
      </Box>
    </>
  );
};
type Props = {
  itemId: string;
};
const WorkItem = ({ itemId }: Props) => {
  const classes = useStyles();

  const { payload: payloadWork } = useQuery(fetchWork);
  const item = payloadWork?.find(({ id }) => id === itemId);

  if (!item) return null;

  const {
    id,
    project_name,
    client_name,
    production_company,
    director_name,
    video_url,
  } = item;

  let link = `work/${id}`;
  if (client_name)
    link += `/${encodeURIComponent(client_name.trim().replaceAll(" ", "-"))}`;
  if (project_name)
    link += `/${encodeURIComponent(project_name.trim().replaceAll(" ", "-"))}`;
  if (production_company)
    link += `/${encodeURIComponent(
      production_company.trim().replaceAll(" ", "-")
    )}`;
  if (director_name)
    link += `/${encodeURIComponent(director_name.trim().replaceAll(" ", "-"))}`;

  return (
    <Template
      title={`${client_name} - ${project_name}`}
      description={`View our work with ${client_name} for the ${project_name} project with ${production_company} and ${director_name}`}
      type="work"
      page={link}
    >
      <>
        <Box className={classes.box}>
          <Box style={{ width: "100%", height: "50vw" }}>
            <iframe
              title={project_name}
              src={`https://player.vimeo.com/video/${video_url}`}
              width="100%"
              height="100%"
              frameBorder="0"
            />
          </Box>
        </Box>
        <Details {...item} />
      </>
    </Template>
  );
};

export default WorkItem;
