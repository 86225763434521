import React, { RefObject, useEffect, useState } from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { Button, makeStyles, Box } from "@material-ui/core";

import { useLocationContext } from "resources/hooks";
import { fetchLocations } from "resources/api";
import { ILocationImage } from "resources/interface";

import LocationRow from "./Row";
import Highlights from "./Highlights";
import { Team } from "pages/Scouting";

const pageSize = 20;

const useStyles = makeStyles(({ breakpoints }) => ({
  load: {
    margin: "30px",
    width: "calc(100vw - 60px)",
    borderRadius: "100vh",
    [breakpoints.up("md")]: {
      margin: "80px 36.5%",
      width: "27%",
    },
  },

  box: {
    padding: "15px 30px",
    [breakpoints.up("md")]: {
      padding: "15px 80px",
    },
  },
}));

interface IProps {
  pageNumber?: number;
  currLocId: string | null;
  scrollRef: RefObject<HTMLDivElement>;
}

const LocationsList = ({ pageNumber = 1, currLocId, scrollRef }: IProps) => {
  const classes = useStyles();
  const { status, loading, payload, query } =
    useParameterizedQuery(fetchLocations);
  const {
    locationContext: { tagList },
  } = useLocationContext();
  let bFound = false;

  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (!tagList.filter(({ id }) => id === "PAUSE").length) {
      const tagIdCSV = tagList
        .filter((tag) => !tag.id.startsWith("%"))
        .reduce((csv, tag) => `${csv}${!csv.length ? "" : ","}${tag.id}`, "");
      const postcodeCSV = tagList
        .filter((tag) => tag.id === "%postcode")
        .reduce(
          (csv, tag) =>
            `${csv}${!csv.length ? "" : ","}${tag.filter_item_title}%20`,
          ""
        );
      const areaCSV = tagList
        .filter((tag) => tag.id === "%area")
        .reduce(
          (csv, tag) =>
            `${csv}${!csv.length ? "" : ","}${tag.filter_item_title}%20`,
          ""
        );
      const imageTag = tagList.find((tag) => tag.id === "%image");

      query({ tagIdCSV, postcodeCSV, areaCSV, pageNumber, pageSize, imageTag });
    }
  }, [pageNumber, tagList.length]);

  useEffect(() => {
    if (status === 504) throw new Error("Image search unavailable");
  }, [status]);

  useEffect(() => {
    if (currLocId && payload && Array.isArray(payload)) {
      if (!payload.some(({ id }) => id === currLocId)) setLoadMore(true);
    }
  }, [payload, currLocId]);

  if (loading || !payload || !Array.isArray(payload)) return <LocationRow />;

  if (!tagList.length)
    return (
      <Box className={classes.box}>
        <Highlights />
        <Box style={{ marginTop: "60px" }}>
          <Team isLibrary />
        </Box>
      </Box>
    );

  return (
    <>
      {payload.map((location) => {
        const {
          loc_image_ps_id1,
          loc_image_file1,
          loc_image_ps_id2,
          loc_image_file2,
          loc_image_ps_id3,
          loc_image_file3,
        } = location;
        const images: Array<ILocationImage> = [];
        if (loc_image_ps_id1 && loc_image_file1)
          images.push({
            ext_itemps_id: loc_image_ps_id1,
            ext_itemps_product_set_image: `${process.env.REACT_APP_HOST}${loc_image_file1}`,
          });
        if (loc_image_ps_id2 && loc_image_file2)
          images.push({
            ext_itemps_id: loc_image_ps_id2,
            ext_itemps_product_set_image: `${process.env.REACT_APP_HOST}${loc_image_file2}`,
          });
        if (loc_image_ps_id3 && loc_image_file3)
          images.push({
            ext_itemps_id: loc_image_ps_id3,
            ext_itemps_product_set_image: `${process.env.REACT_APP_HOST}${loc_image_file3}`,
          });

        const newLoc = {
          ...location,
          images: images,
        };

        if (location.id === currLocId) {
          bFound = true;
          return (
            <div ref={scrollRef} key={location.id}>
              <LocationRow location={newLoc} />
            </div>
          );
        }

        return <LocationRow location={newLoc} key={location.id} />;
      })}
      {payload.length === pageSize && (
        <>
          {loadMore ? (
            <LocationsList
              pageNumber={pageNumber + 1}
              currLocId={bFound ? null : currLocId}
              scrollRef={scrollRef}
            />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.load}
              onClick={() => setLoadMore(true)}
            >
              Load more
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default LocationsList;
