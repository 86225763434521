import React from "react";
import { Chip, Box, Grid, makeStyles } from "@material-ui/core";
import {
  Cancel as CancelIcon,
  ArrowBack as BackIcon,
} from "@material-ui/icons";

import { useLocationContext } from "resources/hooks";
import { ActionType } from "resources/constants";
import { ITag } from "resources/interface";

const useStyles = makeStyles(({ breakpoints }) => ({
  box: {
    padding: "15px 30px",
    [breakpoints.up("md")]: {
      padding: "15px 80px",
    },
  },
}));

const ChosenTags = () => {
  const classes = useStyles();
  const { locationContext, dispatch } = useLocationContext();
  const { tagList } = locationContext;

  const removeItem = (tag: ITag) => {
    dispatch({
      type: ActionType.removeFilterTag,
      selectedTag: tag,
    });
  };

  const removeAll = () => {
    dispatch({
      type: ActionType.removeAllFilters,
    });
  };

  if (!tagList.length) return null;

  return (
    <Box className={classes.box}>
      <Grid container spacing={1}>
        <Grid item>
          <Chip
            color="primary"
            label="Back"
            icon={<BackIcon />}
            deleteIcon={<></>}
            onDelete={removeAll}
            onClick={removeAll}
          />
        </Grid>
        {tagList
          .filter(({ filter_item_title }) => filter_item_title !== "PAUSE")
          .map((tag, index) => (
            <Grid item key={`${tag.id}${index}`}>
              <Chip
                color="primary"
                label={tag.filter_item_title}
                deleteIcon={<CancelIcon />}
                onDelete={() => removeItem(tag)}
                onClick={() => removeItem(tag)}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default ChosenTags;
