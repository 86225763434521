import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { ContextProvider } from "structure/Context";
import { PAGES } from "resources/constants";
import { IMatch } from "resources/interface";

import Tracking from "structure/Tracking";
import ErrorBoundary from "structure/ErrorBoundary";
import ErrorPage from "pages/ErrorPage";
import LocationPage from "pages/Location";
import WorkItemPage from "pages/WorkItem";

const App = () => {
  return (
    <BrowserRouter>
      <Route component={(props: any) => <Tracking {...props} />} />
      <ErrorBoundary>
        <ContextProvider>
          <Switch>
            {Object.values(PAGES).map((page) => (
              <Route
                exact
                key={page.url}
                path={page.url}
                component={page.component}
              />
            ))}
            <Route
              path="/location/:id"
              render={({ match }: IMatch) =>
                match.params.id && <LocationPage id={match.params.id} />
              }
            />
            <Route
              path="/work/:id"
              render={({ match }: IMatch) =>
                match.params.id && <WorkItemPage itemId={match.params.id} />
              }
            />
            <Route
              exact
              path="/imageerr"
              render={() => <ErrorPage text="image" />}
            />
            {/*<Route render={(props: any) => <ErrorPage {...props} text="404" />} />
            <Route component={Home} />*/}
            <Route
              path="*"
              component={() => (
                <>
                  {
                    (window.location.href =
                      "https://saltfilm.com/not-found.aspx")
                  }
                </>
              )}
            />
          </Switch>
        </ContextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
