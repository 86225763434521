import React, { FunctionComponent, useState } from 'react';
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core';

import { useLocationContext, useScript } from 'resources/hooks';
import { ILocationImage } from 'resources/interface';

import { ReactComponent as DropboxIcon } from 'assets/icons/dropbox-icon.svg';

declare var Dropbox: any;

const useStyles = makeStyles(({ palette }) => ({
  box: {
    position: 'relative',
    width: '130px',
    textAlign: 'center',
  },

  icon: {
    color: palette.common.white,
    marginLeft: '-10px',
    '&::before': {
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      paddingBottom: '100%',
      backgroundColor: palette.primary.main,
      content: '""',
      position: 'absolute',
    },
    '& svg': {
      fill: palette.common.white,
      width: '25px',
      height: '25px',
      zIndex: 2,
    },
  },

  text: {
    fontSize: '9px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '0.9px',
  },

  button: {
    borderRadius: '100vh',
    whiteSpace: 'nowrap',
    maxHeight: '32px',
    textTransform: 'none',
    marginLeft: '10px',
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    '& svg': {
      width: '20px',
      marginLeft: '15px',
    },
  },
}));

enum Status {
  waiting,
  uploading,
  complete,
  error,
}

interface IDropbox {
  url: string;
  filename: string;
}

interface IProps {
  locId?: string;
  images?: ILocationImage[];
  basket?: boolean;
}
const DropboxImages: FunctionComponent<IProps> = ({ locId, images, basket = false }) => {
  const classes = useStyles();

  const [scriptLoaded, scriptError] = useScript({
    src: 'https://www.dropbox.com/static/api/2/dropins.js',
    dataAppKey: '5b4dsaw3dacbgxn',
  });
  const [uploadStatus, setUploadStatus] = useState(Status.waiting);
  const { locationContext } = useLocationContext();
  const { basketList } = locationContext;

  const dropboxSaver = () => {
    const emptyList: Array<IDropbox> = [];
    let fileList = emptyList;

    if (basket) {
      fileList = basketList
        .filter(({ id }) => id === locId)
        .reduce((fileList, location) => {
          const newArray =
            location.images?.map(({ ext_itemps_id, ext_itemps_product_set_image }) => {
              return {
                url: ext_itemps_product_set_image,
                filename: `Salt-Loc-${location.id}-Img-${ext_itemps_id}.jpg`,
              };
            }) || emptyList;

          return [...fileList, ...newArray];
        }, emptyList);
    } else if (images) {
      fileList = images.map((image) => {
        return {
          url: image.ext_itemps_product_set_image,
          filename: `Salt-Loc-${locId}-Img-${image.ext_itemps_id}.jpg`,
        };
      });
    } /*else {
      fileList = basketList.reduce((fileList, location) => {
        const newArray =
          location.images?.map(({ ext_itemps_id, ext_itemps_product_set_image }) => {
            return {
              url: ext_itemps_product_set_image,
              filename: `Salt-Loc-${location.id}-Img-${ext_itemps_id}.jpg`,
            };
          }) || emptyList;

        return [...fileList, ...newArray];
      }, emptyList);
    }*/

    const options = {
      files: fileList,
      success: () => {
        alert('The images have been saved to your Dropbox');
        setUploadStatus(Status.complete);
      },
      progress: () => setUploadStatus(Status.uploading),
      cancel: () => setUploadStatus(Status.waiting),
      error: (errorMessage: string) => {
        errorMessage
          ? alert('Dropbox reported a problem: ' + errorMessage)
          : alert(
              'Dropbox failed to upload the files, please try again and if it still fails, try creating a new Dropbox folder.'
            );
        setUploadStatus(Status.error);
      },
    };

    Dropbox.save(options);
  };

  /*  if (uploadStatus === Status.uploading) return <Typography>[uploading]</Typography>;
  if (uploadStatus === Status.complete) return <Typography>Upload complete</Typography>;
  if (uploadStatus === Status.error) return <Typography>Problem uploading to Dropbox</Typography>; */

  if (scriptLoaded && !scriptError) {
    if (basket)
      return (
        <Button variant="contained" className={classes.button} onClick={dropboxSaver}>
          Dropbox
          <DropboxIcon />
        </Button>
      );

    return (
      <Box className={classes.box}>
        <IconButton
          disabled={uploadStatus === Status.uploading}
          edge="end"
          className={classes.icon}
          onClick={dropboxSaver}
        >
          <DropboxIcon />
        </IconButton>
        <Typography className={classes.text}>Dropbox</Typography>
      </Box>
    );
  }
  return null;
};

export default DropboxImages;
