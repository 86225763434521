import React, { useState, useEffect } from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";

import FGI from "tags/FGI";

import { ReactComponent as FilterIcon } from "assets/icons/filters.svg";
import { ActionType } from "resources/constants";
import { useLocationContext } from "resources/hooks";

const useStyles = makeStyles(() => ({
  button: {
    width: "30%",
    height: "54px",
    border: "none",
    borderRadius: "100vh",
    backgroundColor: "#99A4A0",
    outline: "none",
    "& svg": {
      verticalAlign: "middle",
      margin: "-5px 10px 0 10px",
      width: "20px",
      height: "20px",
    },
    "&:hover": {
      backgroundColor: "#193027",
    },
  },

  label: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontSize: "14px",
    marginLeft: "15px",
  },
}));

const AZSearch = () => {
  const classes = useStyles();
  const { dispatch, locationContext } = useLocationContext();
  const [showPopup, setShowPopup] = useState(false);

  const { tagList } = locationContext;

  useEffect(() => {
    if (!showPopup && tagList.some(({ id }) => id === "PAUSE")) {
      dispatch({
        type: ActionType.removeFilterTag,
        selectedTag: { id: "PAUSE", filter_item_title: "PAUSE" },
      });
    }
  }, [showPopup, tagList]);

  return (
    <>
      <IconButton className={classes.button} onClick={() => setShowPopup(true)}>
        <Typography className={classes.label}>Search Filters</Typography>
        <FilterIcon />
      </IconButton>
      {showPopup && <FGI action={() => setShowPopup(false)} />}
    </>
  );
};

export default AZSearch;
