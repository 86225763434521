import React, { FunctionComponent, forwardRef } from "react";
import { Link as RouterLink, LinkProps, Link } from "react-router-dom";
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
  Theme,
  Typography,
} from "@material-ui/core";
import { ShoppingBasketOutlined as BasketIcon } from "@material-ui/icons";

import { useLocationContext } from "resources/hooks";
import { PAGES } from "resources/constants";

import Logo from "./Logo";
//import { ReactComponent as BasketIcon } from 'assets/icons/bag-icon.svg';
import { ReactComponent as MenuIcon } from "assets/icons/burger-icon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/cross-icon.svg";

interface IStyleProps {
  homePage?: boolean;
  libPage?: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>(
  ({ palette, breakpoints }) => ({
    appbar: {
      boxShadow: "inset 0 -1px 0 0 #e1e1e1, inset 0 1px 0 0 #e1e1e1",
      padding: "30px",
      backgroundColor: palette.common.white,
      color: palette.primary.main,
      height: "112px",
      [breakpoints.up("md")]: {
        padding: "35px 80px",
        height: "152px",
      },
      "& h1": {
        fontSize: "1em",
        marginLeft: "10px",
      },
    },

    appbarHome: {
      zIndex: 2,
      position: "absolute",
      padding: "30px",
      backgroundColor: "transparent",
      boxShadow: "none",
      [breakpoints.up("md")]: {
        padding: "35px 80px",
      },
    },

    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },

    mobile: {
      "& path": {
        fill: (props) =>
          props.libPage ? palette.common.white : palette.primary.main,
      },
      [breakpoints.up("md")]: {
        display: "none",
      },
    },

    menu: {
      display: "none",
      padding: 0,
      [breakpoints.up("md")]: {
        display: "flex",
      },
    },

    menuItem: {
      marginTop: 0,
      width: "max-content",
      "& span": {
        color: (props) =>
          props.homePage ? palette.common.white : palette.primary.main,
      },
      "&:hover": {
        "& span": {
          textDecoration: "underline",
        },
      },
    },

    highlight: {
      textDecoration: "underline",
    },

    icon: {
      color: (props) =>
        props.homePage ? palette.primary.main : palette.common.white,
      backgroundColor: (props) =>
        props.homePage ? palette.common.white : palette.primary.main,
      marginLeft: "20px",
      width: "50px",
      height: "50px",
      "&::before": {
        borderRadius: "50%",
        width: "100%",
        paddingBottom: "100%",
        content: '""',
        position: "absolute",
        zIndex: -1,
      },
      "& svg": {
        width: "25px",
        height: "25px",
      },
    },
    basketCount: {
      color: (props) =>
        props.homePage ? palette.primary.main : palette.common.white,
      width: "17px",
      fontSize: "0.5em",
      marginTop: "-25px",
      marginLeft: "-10px",
    },

    title: {
      marginLeft: "0 !important",
      marginTop: "-15px",
      [breakpoints.up("md")]: {
        marginLeft: "10px !important",
        marginTop: 0,
      },
    },
  })
);

interface IProps {
  pageType: string;
  title: string;
  useClose?: boolean;
  handleClick: () => void;
}
const SaltAppBar: FunctionComponent<IProps> = ({
  pageType,
  title,
  useClose = false,
  handleClick,
}) => {
  const classes = useStyles({
    homePage: pageType === "home",
    libPage: pageType === "library",
  });
  const { locationContext } = useLocationContext();
  const { basketList } = locationContext;

  return (
    <AppBar
      position="relative"
      className={pageType === "home" ? classes.appbarHome : classes.appbar}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <Logo homePage={pageType === "home"} />
        <List className={classes.menu}>
          {Object.values(PAGES)
            .filter(({ menuHide }) => !menuHide)
            .map((page) => (
              <ListItem
                button
                key={page.url}
                component={forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
                  (props, ref) => (
                    <Link to={page.url} {...props} ref={ref as any} />
                  )
                )}
              >
                <ListItemText
                  primary={page.menu}
                  className={`${classes.menuItem} ${
                    pageType === page.url.replace("/", "")
                      ? classes.highlight
                      : ""
                  }`}
                />
              </ListItem>
            ))}
        </List>
        <Box>
          <IconButton
            className={classes.icon}
            aria-label="basket"
            component={RouterLink}
            to={`/basket`}
            onClick={handleClick}
          >
            <BasketIcon fill="white" />
            {!!basketList.length && (
              <div className={classes.basketCount}>{basketList.length}</div>
            )}
          </IconButton>
          <IconButton
            className={`${classes.icon} ${classes.mobile}`}
            aria-label="menu"
            onClick={handleClick}
          >
            {useClose ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
      </Toolbar>
      {pageType !== "home" && pageType !== "work" && (
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      )}
    </AppBar>
  );
};

export default SaltAppBar;
