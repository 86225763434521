import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ChevronLeft as PrevIcon,
  ChevronRight as NextIcon,
} from "@material-ui/icons";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import t1 from "assets/testimonials/1 -Dorota EMIGRACJA XD .jpg";
import t2 from "assets/testimonials/2-Travesuras del la Nina - Rocio Lopez.jpg";
//import t3 from "assets/testimonials/3 -Young Suh.jpg";
import t4 from "assets/testimonials/4 - Alberto Ministerio dos Filmes.jpg";
import t5 from "assets/testimonials/5 - Matt McKenzie.jpg";
import t6 from "assets/testimonials/6 - Tim-Katz.jpg";
import t7 from "assets/testimonials/7 - Film London.jpg";

const testimons = [
  {
    quote: "Working with Salt Film was an outstanding experience",
    from: "Dorota Kosmicka Producer Jake Vision Poland",
    image: t1,
    note: "Filming at London's iconic Piccadilly Circus from an epic bird's-eye view camera position",
  },
  {
    quote: "You feel like you are shooting at home",
    from: "Rocio Lopez Producer TV Group Digital Iberia",
    image: t2,
    note: "Magic hour filming at London’s Tower Bridge on the River Thames",
  },
  // {
  //   quote: 'We were grateful to see a service producer taking on a production as if it was their own. Highly recommended!',
  //   from:  'Young Suh, Studio Flow, Korea',
  //   image: t3,
  // },
  {
    quote: "They are really fantastic and always provide solutions",
    from: "Alberto Rodrigues Ministerio Producer Dos Filmes, Portugal",
    image: t4,
    note: "Filming in London’s Chinatown",
  },
  {
    quote: "Salt’s assistance with our UK production leg was simply invaluable",
    from: "Matt McKenzie - Producer, Supernormal, NZ",
    image: t5,
    note: "Filming inside the historic Bodleian Library at the University of Oxford",
  },
  {
    quote: "If you need a location in the UK, just call Salt",
    from: "Tim Katz Managing Partner, Knucklehead",
    image: t6,
    note: "Filming at the London Eye on the Southbank and the Highlands of Scotland",
  },
  {
    quote: "Salt continue to be one of the UK’s leading location agencies",
    from: "Film London",
    image: t7,
    note: "Filming on the Thames path overlooking the City of London skyline",
  },
];

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  box: {
    position: "relative",
    backgroundImage: `linear-gradient(${palette.primary.main} 65%, ${palette.common.white} 0%)`,
    padding: "50px 30px",
    marginTop: "10px",
    "& h2": {
      color: palette.common.white,
    },
    "& h3": {
      color: "#D9E1CC",
      height: "100px",
      overflow: "hidden",
      [breakpoints.up("md")]: {
        fontSize: "36px",
        lineHeight: "50px",
        height: "110px",
        marginBottom: "10px",
      },
    },
    "& p": {
      color: palette.common.white,
      marginBottom: "20px",
    },
    [breakpoints.up("md")]: {
      backgroundImage: `linear-gradient(${palette.primary.main} 60%, ${palette.common.white} 0%)`,
      padding: "100px 80px",
    },
  },

  details: {
    textAlign: "left",
    [breakpoints.up("md")]: {
      padding: "0 100px",
    },
  },

  carousel: {
    "& .control-dots": {
      top: "170px",
      width: "200px",
      left: "65px",
    },
  },

  icon: {
    position: "absolute",
    top: "180px",
    "&::before": {
      border: "1px solid black",
      borderRadius: "50%",
      width: "4vh",
      height: "4vh",
      backgroundColor: "#99A4A0",
      content: '""',
      position: "absolute",
    },
    "& svg": {
      fill: palette.primary.main,
      width: "3vh",
      height: "3vh",
      zIndex: 2,
    },
  },

  button: {
    width: "fit-content",
    padding: 0,
    borderRadius: 0,
    marginRight: "20px",
  },
  icon2: {
    width: "20px",
    height: "20px",
    marginRight: "5px",
    color: palette.primary.main,
  },

  note: {
    color: "#707070",
    fontSize: "14px",
    textAlign: "left",
    marginTop: "30px",
    [breakpoints.up("md")]: {
      width: "420px",
      float: "right",
      fontSize: "17px",
    },
  },

  image: {
    marginTop: "30px",
    [breakpoints.up("md")]: {
      marginTop: "100px",
    },
  },
}));

interface IProps {}
const Testimonials = ({}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const [currentSlide, setCurrentSlide] = useState(0);

  const prev = () => setCurrentSlide(currentSlide - 1);
  const next = () => setCurrentSlide(currentSlide + 1);

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) setCurrentSlide(index);
  };

  return (
    <>
      <Box className={classes.box}>
        <Box className={classes.details}>
          <Typography variant="h2">Testimonials</Typography>
        </Box>
        <Carousel
          selectedItem={currentSlide}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          showIndicators={desktop}
          useKeyboardArrows
          onChange={updateCurrentSlide}
          autoPlay
          infiniteLoop
          interval={5000}
          className={classes.carousel}
        >
          {testimons.map(({ quote, from, image, note }, index) => {
            return (
              <Box key={index}>
                <Box className={classes.details}>
                  <Typography variant="h3">&lsquo;{quote}&rsquo;</Typography>
                  <Typography variant="body1">{from}</Typography>
                </Box>
                <img alt={from} src={image} className={classes.image} />
                {note && <Box className={classes.note}>{note}</Box>}
              </Box>
            );
          })}
        </Carousel>
        {desktop && (
          <>
            <IconButton
              edge="end"
              className={classes.icon}
              onClick={prev}
              style={{ left: "70px" }}
            >
              <PrevIcon />
            </IconButton>
            <IconButton
              edge="end"
              className={classes.icon}
              onClick={next}
              style={{ right: "80px" }}
            >
              <NextIcon />
            </IconButton>
          </>
        )}
      </Box>
    </>
  );
};

export default Testimonials;
